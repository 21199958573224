import LcView from "lib/shared/view";

export default LcView.create({
  ready() {
    this.initializeListeners();
    this.removeInlineLinks();
  },

  initializeListeners() {
    $(this.selectors.MOCK_BROWSER_GRIPPER).on("mousedown touchstart", (e) => {
      this.handleMockBrowserGripStart(e);
    });
    $("body").on("mouseup touchend", (e) => {
      this.handleMockBrowserGripEnd(e);
    });
    $(this.selectors.MOCK_BROWSER).on("mousemove touchmove", (e) => {
      this.handleMockBrowserGripMove(e);
    });
    $(window).on("resize", () => {
      this.handleMockBrowserResize();
    });
  },

  removeInlineLinks() {
    const $mockBrowser = $(this.selectors.MOCK_BROWSER);
    const $links = $mockBrowser.find(this.selectors.INLINE_LINK);
    $links.each((i, link) => {
      $(link).removeAttr("href");
    });
  },

  selectors: {
    MAIN: ".sign-up-wrap",
    MOCK_BROWSER: ".sign-up__mock-browser",
    MOCK_BROWSER_CONTENT: ".sign-up__mock-browser--content",
    MOCK_BROWSER_EXPANDABLE_COL: ".sign-up__mock-browser--expandable-col",
    MOCK_BROWSER_EXPANDABLE_CONTENT:
      ".sign-up__mock-browser--expandable-content",
    MOCK_BROWSER_STATIC_CONTENT: ".sign-up__mock-browser--static-content",
    MOCK_BROWSER_GRIPPER: ".sign-up__mock-browser--gripper",
    INLINE_LINK: "a.poem-inline__link",
    GROUPS_PROMO_LINK: ".groups-promo__link",
    PLAN_SWITCH_TO_MONTHLY_LINK: ".selected-subscription-plan__switch--monthly",
    PLAN_SWITCH_TO_ANNUAL_LINK: ".selected-subscription-plan__switch--annual",
  },

  handleMockBrowserGripStart(e) {
    const $mockBrowser = $(e.target).closest(this.selectors.MOCK_BROWSER);
    $mockBrowser.attr("data-gripped", "true");
  },

  handleMockBrowserGripEnd() {
    const $mockBrowser = $(this.selectors.MOCK_BROWSER);
    $mockBrowser.removeAttr("data-gripped");
  },

  handleMockBrowserGripMove(e) {
    const $mockBrowser = $(e.target).closest(this.selectors.MOCK_BROWSER);
    if (!$mockBrowser.attr("data-gripped")) {
      return;
    }
    const $expandableCol = $mockBrowser.find(
      this.selectors.MOCK_BROWSER_EXPANDABLE_COL
    );
    const $expandableContent = $mockBrowser.find(
      this.selectors.MOCK_BROWSER_EXPANDABLE_CONTENT
    );
    const $staticContent = $mockBrowser.find(
      this.selectors.MOCK_BROWSER_STATIC_CONTENT
    );
    const $gripper = $mockBrowser.find(this.selectors.MOCK_BROWSER_GRIPPER);

    const pageX =
      e.pageX ||
      (e.originalEvent &&
        e.originalEvent.touches &&
        e.originalEvent.touches[0] &&
        e.originalEvent.touches[0].pageX);
    const offset = $staticContent.offset().left;
    let x = pageX - offset;
    const width = $staticContent.width();
    const isLtr = $expandableCol.data("ltr");
    const borderWidth = isLtr
      ? parseInt($expandableCol.css("border-right-width")) || 0
      : parseInt($expandableCol.css("border-left-width")) || 0;
    const padding = parseInt($staticContent.css("padding-left")) || 0;

    if (x < padding + borderWidth) {
      x = padding + borderWidth;
    }
    if (x > width + padding) {
      x = width + padding;
    }

    // account for $expandableCol's border, staticContent's padding, and half the gripper width
    let gripperX = Math.min(x, width + padding) - borderWidth / 2;
    if (isLtr) {
      gripperX -= $gripper.width() / 2;
    }

    // the default expansion direction would be right-to-left, but if inverted, use data-ltr
    if (isLtr) {
      $expandableCol.css({ width: x });
      $expandableCol.attr("data-expanded-scale", x / width); // use scale for responsiveness if resized
    } else {
      $expandableCol.css({ width: width - x });
      $expandableContent.css({ width });
      $expandableCol.attr("data-expanded-scale", (width - x) / width); // use scale for responsiveness if resized
    }
    $gripper.css({ left: gripperX });
  },

  // size of the mock browser is scaled down on resize, so also need to scale down width of expandableCol and gripper offset
  handleMockBrowserResize() {
    const $mockBrowser = $(this.selectors.MOCK_BROWSER);
    const $expandableCol = $mockBrowser.find(
      this.selectors.MOCK_BROWSER_EXPANDABLE_COL
    );
    const $staticContent = $mockBrowser.find(
      this.selectors.MOCK_BROWSER_STATIC_CONTENT
    );
    const $gripper = $mockBrowser.find(this.selectors.MOCK_BROWSER_GRIPPER);

    const width = $staticContent.width();
    const borderWidth = parseInt($expandableCol.css("border-right-width")) || 0;
    const scale = $expandableCol.attr("data-expanded-scale");
    const gripperX = width * scale - $gripper.width() / 2 - borderWidth / 2;

    $expandableCol.css({ width: width * scale });

    $gripper.css({ left: gripperX });
  },
});
