export default {
  // Convert num to 0th, 1st, 2nd 3rd etc, add more edge cases as needed
  cardinalize(num) {
    if (num === null || num === undefined) return null;
    if (Number.isNaN(num)) return null;
    const lastChar = num.toString().split("").pop();
    const lastDigit = parseInt(lastChar);
    let suffix = "th";

    switch (lastDigit) {
      case 1:
        suffix = "st";
        break;
      case 2:
        suffix = "nd";
        break;
      case 3:
        suffix = "rd";
        break;
      default:
        suffix = "th";
        break;
    }

    return `${num}${suffix}`;
  },

  isNumber(str) {
    return str.match(/^\d+$/);
  },

  // capitalize each word of a string
  capitalize(str) {
    return str
      .toLowerCase()
      .replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
  },

  capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  // Decodes an encoded html string
  htmlDecode(text) {
    const doc = new window.DOMParser().parseFromString(text, "text/html");
    return doc.documentElement.textContent;
  },

  // parameterize a string
  parameterize(str) {
    return str
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "");
  },

  // allows for multiline regexes by taking in an array of regexes and joining them
  multilineRegex(regs, options = { join: "|" }) {
    return new RegExp(regs.map((reg) => reg.source).join(options.join));
  },

  // truncates a string to include at most n characters including an ellipses if needed
  truncate(str, n) {
    if (str.length <= n) {
      return str;
    }

    return `${str.slice(0, n - 3)}...`;
  },
};
