export default {
  commaSeparateNumber(number) {
    return Number(number).toLocaleString("en");
  },

  // https://stackoverflow.com/a/31615643
  ordinalize(n) {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  },
};
