import React from "react";

import { ToastContainer, cssTransition } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";

injectStyle();

const animations = cssTransition({
  enter: "slide-in-bottom",
  exit: "slide-out-bottom",
});

function CloseButton({ closeToast }) {
  return (
    <span className="Toastify__close-button">
      <i
        className="lc-icon lc-icon-xs lc-icon-close"
        data-amplitude="toasterCloseIcon"
        onClick={closeToast}
        onKeyDown={closeToast}
        role="presentation"
      />
    </span>
  );
}

export default function Toaster() {
  return (
    <ToastContainer
      className="lc-toaster"
      closeButton={CloseButton}
      pauseOnFocusLoss={false}
      transition={animations}
    />
  );
}
