import consumer from "./consumer";

const qAndAiChannel = function () {
  consumer.subscriptions.create("QAndAiChannel", {
    connected() {
      // console.log('connected');
    },

    disconnected() {
      // console.log('disconnected')
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      if (data.body) {
        $("#response-area").html(data.body);
      } else if (data.stop) {
        console.log("End of transmission!");
      }
    },
  });
};

export default qAndAiChannel;
