export default {
  // require the current view file based on the controller-action path
  initialize: () => {
    const path = $("html").data("controller-path");
    if (!path) {
      return;
    }
    try {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      const view = require(`views/${path}`);
      $().ready(view.default.ready.call(view.default));
    } catch (error) {
      if (error.message.match(/^Cannot find module/)) {
        console.warn(error.message);
        console.warn(`----> Unable to require 'views/${path}', skipping...`);
      } else {
        throw error;
      }
    }
  },
};
