import LcView from "lib/shared/view";
import helpers from "helpers/base";

export default LcView.create({
  ready() {
    this.setDuplicateLiteraryDevices();
    this.setListeners();
    this.insertPaywallBlockerLinks();
    this.scrollToLiteraryDevice();
  },

  setListeners() {
    this.setToggleListeners();
    this.setModalListeners();
  },

  // add modal listeners for each cite button after each quote
  setModalListeners() {
    const buttons = document.querySelectorAll(this.selectors.CITE_BUTTON);
    buttons.forEach((button) => {
      button.addEventListener("click", (_) => {
        if (helpers.isSubscribed()) {
          // modals won't exist for unsubscribed users
          const literaryDevice = button.closest(this.selectors.LITERARY_DEVICE);
          const modalSelector = `#${literaryDevice.id}-modal`;
          $(modalSelector).modal("show");
        } else {
          window.location.href = button.getAttribute("href");
        }
      });
    });
  },

  // add listeners to toggle expandable/collapsible list groups
  setToggleListeners() {
    const toggles = document.querySelectorAll(this.selectors.TOGGLE_GROUP);
    toggles.forEach((toggle) => {
      toggle.addEventListener("click", () => {
        const content = toggle.parentElement.querySelector(
          this.selectors.TOGGLE_CONTENT
        );
        $(content).slideToggle(() => {
          content.classList.toggle("hidden");
        });
        if (toggle.getAttribute("aria-expanded") === null) {
          toggle.setAttribute("aria-expanded", "");
          toggle.classList.remove("rounded-corners");
          toggle.setAttribute(
            "aria-label",
            toggle.getAttribute("aria-label-expanded")
          );
        } else {
          toggle.classList.add("rounded-corners");
          toggle.removeAttribute("aria-expanded");
          toggle.setAttribute(
            "aria-label",
            toggle.getAttribute("aria-label-collapsed")
          );
        }
      });
    });
  },

  setDuplicateLiteraryDevices() {
    // Lit devices that span multiple summary sections display in a different way
    const elements = Array.from(
      document.getElementsByClassName("literary-device")
    );
    const seen = {};
    elements.forEach((element) => {
      const id = element.getAttribute("data-lit-device-id");
      const instanceName = element.getAttribute("data-instance-name");
      if (Object.keys(seen).includes(id)) {
        // already seen this lit device before
        element.setAttribute("data-is-duplicate", "");
        const title = element.querySelector(
          ".literary-device__title--duplicate"
        );
        title.innerHTML = `Covered in "${seen[id].firstInstance}"`;
        title.setAttribute("href", `#${seen[id].elementId}`);
      } else {
        seen[id] = { firstInstance: instanceName, elementId: element.id };
      }
    });
  },

  scrollToLiteraryDevice() {
    const sectionId = helpers.getQueryParam("chapter");
    const summaryId = helpers.getQueryParam("summary");
    if (sectionId) {
      let element = null;
      if (summaryId) {
        // if summary id is present, find first lit device within the section associated with that summary
        const section = document.querySelector(`#${sectionId}`);
        const litDevices = section.querySelectorAll(
          this.selectors.LITERARY_DEVICE
        );
        element = Array.from(litDevices).find((litDevice) => {
          const summaryIds = JSON.parse(
            litDevice.getAttribute("data-summaries")
          );
          return summaryIds.includes(parseInt(summaryId));
        });
        // otherwise, just scroll to the section
      } else {
        element = document.querySelector(`[aria-controls=${sectionId}]`);
      }

      if (element) {
        window.history.scrollRestoration = "manual"; // disable default scroll restoration on page load
        const subnav = document.querySelector(this.selectors.BOOK_NAV);
        const mobileHeaderPromo = document.querySelector(
          this.selectors.MOBILE_HEADER_PROMO
        );

        // scroll to the element's page offset minus sticky banners
        // subnav's height is doubled since it contributes to the element's page offset while it's still on the page,
        // but once scrolled past it becomes sticky
        const offset =
          $(element.parentElement.parentElement).offset().top -
          2 *
            (helpers.dom.isElementVisible(subnav)
              ? subnav.offsetHeight + 6
              : -4) - // sticky subnav height
          (helpers.dom.isElementVisible(mobileHeaderPromo)
            ? mobileHeaderPromo.offsetHeight
            : 0); // sticky mobile promo height
        $("html, body").animate({ scrollTop: offset }, 200);
      }
    }
  },

  // the paywall blocker is rendered in the body of the lit device, but a plus links are added in the template
  // move a plus links for each lit device into it's corresponding container node
  insertPaywallBlockerLinks() {
    document
      .querySelectorAll(this.selectors.LITERARY_DEVICE)
      .forEach((litDevice) => {
        const link = litDevice.querySelector(this.selectors.A_PLUS_BUTTON);
        const container = litDevice.querySelector(
          this.selectors.A_PLUS_DIALOG_CONTAINER
        );
        if (link && container) {
          link.remove();
          container.appendChild(link);
        }
      });
  },

  selectors: {
    TOGGLE_GROUP: ".toggle-group:not(:disabled)",
    TOGGLE_CONTENT: ".summary-section-literary-devices",
    CITE_BUTTON: ".literary-device__cite",
    CITE_MODAL: ".modal",
    LITERARY_DEVICE: ".literary-device",
    BOOK_NAV: ".book-nav",
    MOBILE_HEADER_PROMO: "#sticky-mobile-header-promo",
    A_PLUS_BUTTON: ".btn-a-plus",
    A_PLUS_DIALOG_CONTAINER: ".a-plus-dialog__container",
  },
});
