import React, { useState } from "react";

const Banner = ({ confirmedBannerUrl, bannerKey }) => {
  const [showBanner, setShowBanner] = useState(true);

  const messages = {
    privacy_policy_banner: `We have updated our <u><a href="/privacy">Privacy Policy</a></u>.
    By continuing to use our site, you confirm that you have read our updated Privacy Policy.`,
    maintenance_sep2024_banner: `LitCharts will be undergoing maintenance and will be unavailable
    for a brief time on Friday, September 20.`,
  };

  const confirmButton = (e) => {
    e.preventDefault();
    const requestUrl = `${confirmedBannerUrl}?banner_key=${bannerKey}`;
    fetch(requestUrl, {
      headers: {
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
      },
      method: "PATCH",
    }).then(() => {
      setShowBanner(false);
    });
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div id={bannerKey} className="sitewide-banner-container">
      <div className="sitewide-banner">
        <div>
          <div className="banner-1 content-container">
            <span dangerouslySetInnerHTML={{ __html: messages[bannerKey] }} />
            <button
              type="button"
              onClick={confirmButton}
              className="btn btn-default btn-sm confirm-button"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
