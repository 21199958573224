import React, { useRef, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import helpers from "helpers/base";
import AppBanner from "./AppBanner";

const defaultProps = {
  authType: "",
  flow: null,
  size: "md",
  text: "Sign up with Facebook",
};

const setErrorMessage = (message) => {
  AppBanner.setContent(message);
};

const clearErrorMessage = () => {
  AppBanner.clear();
};

const authCallback = (
  response,
  successHandler,
  errorHandler,
  flow = "sign-in",
  openRequestLitGuideModal = false
) => {
  let url = "/auth/facebook/callback";
  if (flow) {
    url = `${url}?flow=${flow}`;
  }
  Object.assign(response, {
    open_request_lit_guide_modal: openRequestLitGuideModal,
  });
  jQuery.ajax({
    type: "POST",
    data: response,
    success: successHandler,
    error: errorHandler,
    url,
  });
};

function FacebookLoginButton(props) {
  let { callback } = props;
  const [authType, setAuthType] = useState(props.authType);
  const buttonRef = useRef(null);

  if (!callback) {
    callback = (response) => {
      setErrorMessage(null);

      const onAuthenticationSuccess = (_data, _textStatus, jqXHR) => {
        const url = jqXHR.getResponseHeader("Location");
        if (url) {
          window.location.href = url;
        } else {
          $(buttonRef.current).trigger("oauthRegistrationInProgress");
        }
      };

      const onAuthenticationError = (jqXHR, _textStatus, _errorThrown) => {
        const errorMessage = helpers.object.dig(jqXHR, "responseJSON.error");
        const emailRequired = helpers.object.dig(
          jqXHR,
          "responseJSON.email_required"
        );
        if (errorMessage) {
          setErrorMessage(errorMessage);
        } else {
          console.error("Unrecognized Facebook authentication error:", jqXHR);
          setErrorMessage(
            `There was an error signing in with Facebook.
            Please try again or contact LitCharts support if the problem persists.`
          );
        }
        if (emailRequired) {
          // User declined email address permission, so we must re-request this permission, see docs:
          // https://developers.facebook.com/docs/facebook-login/web/permissions
          setAuthType("rerequest");
        }
      };

      if (response && !response.error) {
        authCallback(
          response,
          onAuthenticationSuccess,
          onAuthenticationError,
          props.flow,
          props.openRequestLitGuideModal
        );
      } else if (response && response.error) {
        const errorObj = response.error;
        console.error("Facebook authentication error:", errorObj);
        const errorStr =
          typeof errorObj === "string" ? errorObj : JSON.stringify(errorObj);
        const errorMessage = `There was an error signing up with Facebook: "${errorStr}".
      Please try again or contact LitCharts support if the problem persists.`;
        setErrorMessage(errorMessage);
      }
    };
  }

  return (
    <FacebookLogin
      appId={props.appId}
      callback={callback}
      cookie
      fields="email"
      scope="email"
      authType={authType}
      disableMobileRedirect
      onClick={clearErrorMessage}
      // eslint-disable-next-line react/jsx-no-bind
      render={(renderProps) => (
        <button
          ref={buttonRef}
          type="button"
          data-provider="facebook"
          onClick={renderProps.onClick}
          id="sign-in-with-facebook"
          className={`btn btn-default btn-${props.size} btn-social`}
        >
          {props.iconSrc && <img src={props.iconSrc} alt="Facebook logo" />}
          <span>{props.text}</span>
        </button>
      )}
    />
  );
}

FacebookLoginButton.defaultProps = defaultProps;

export default FacebookLoginButton;
