import LcView from "lib/shared/view";
import discussionQuestionsChannel from "channels/discussion_questions_channel";

export default LcView.create({
  ready() {
    $("body").on("submit", "form#prototype-form", function () {
      $.ajax({
        type: "POST",
        url: $(this).attr("action"),
        data: $(this).serialize(),
        dataType: "json",
        beforeSend() {
          $("#result-indicator").html("Submitted. Please wait.");
          $("#response-area").html("");
          $("#prompt-area").html("");
        },
        success(data, _status, _settings) {
          const finalPrompt = data.final_prompt;
          $("#prompt-area").text(finalPrompt);
        },
        error(data, status, _settings) {
          if (status === "timeout") {
            $("#result-indicator").html("Timed out.");
          } else {
            $("#result-indicator").html(data.responseText);
          }
        },
      });
      return false;
    });

    $("#resource").change(function () {
      const url = new URL(window.location.href);
      url.searchParams.set("resource", $(this).val());
      window.location.assign(url.search);
    });

    $("#focus").change(function () {
      $.ajax({
        type: "GET",
        url: $(this).data("subfocusUrl"),
        data: {
          focus: $(this).val(),
          resource: $("#resource").val(),
        },
        dataType: "json",
        success(data) {
          const { subfocuses } = data;
          const subfocusOptions = $("#subfocus");

          subfocusOptions.empty();
          subfocuses.forEach((subfocus) => {
            const option = document.createElement("option");
            option.innerText = subfocus;
            subfocusOptions.append(option);
          });
        },
        error(data) {
          $("#result-indicator").html(data.responseText);
        },
      });
    });

    discussionQuestionsChannel();
  },
});
