import LcView from "lib/shared/view";
import helpOnDemandPromo from "views/shared/help_on_demand/promo";

export default LcView.create({
  ready() {
    this.hodPromoIsRevealed = false;
    setTimeout(() => {
      helpOnDemandPromo.setOffset();
      this.hodPromoIsRevealed = true;
    }, 3000);
    helpOnDemandPromo.ready();
    this.setListeners();
  },

  setListeners() {
    $(window).on("resize", () => {
      if (this.hodPromoIsRevealed) {
        helpOnDemandPromo.setOffset();
      }
    });
    $(this.selectors.HOD_PROMO_CTA).click(() => {
      this.handleHelpOnDemandPromoClick();
    });
  },

  handleHelpOnDemandPromoClick() {},

  selectors: {
    BOOK_COMPONENT: ".book-component",
    HOD_PROMO_CTA: ".hod-promo__cta",
  },
});
