import LcView from "lib/shared/view";
import quizQuestionsChannel from "../../../../channels/quiz_questions_channel";

export default LcView.create({
  ready() {
    $("body").on("submit", "form#prototype-form", function () {
      $.ajax({
        type: "POST",
        url: $(this).attr("action"),
        data: $(this).serialize(),
        dataType: "json",
        beforeSend() {
          $("#result-indicator").html("Submitted. Please wait.");
          $("#response-area").html("");
          $("#prompt-area").html("");
          $("#guide-portion-sent").html("");
        },
        success(data, _status, _settings) {
          const finalPrompt = data.final_prompt;
          const guidePortionSent = data.guide_portion_sent;

          $("#prompt-area").text(finalPrompt);
          $("#guide-portion-sent").text(guidePortionSent);
        },
        error(data, status, _settings) {
          if (status === "timeout") {
            $("#result-indicator").html("Timed out.");
          } else {
            $("#result-indicator").html(data.responseText);
          }
        },
      });
      return false;
    });

    $("#quiz_focus").change(function () {
      const summarySectionOptions = $("#summary_section");
      summarySectionOptions.empty();

      if ($(this).val() === "Summary & Analysis") {
        $.ajax({
          type: "GET",
          url: $(this).data("summarySectionsPath"),
          data: { focus: $(this).val(), resource_id: $("#resource").val() },
          dataType: "json",
          success(data) {
            data.summary_sections.forEach((summarySection) => {
              const option = document.createElement("option");
              [option.innerText, option.value] = summarySection;
              summarySectionOptions.append(option);
            });
          },
          error(data) {
            $("#result-indicator").html(data.responseText);
          },
        });
      }
    });
    quizQuestionsChannel();
  },
});
