import React from "react";
import PropTypes from "prop-types";
import help from "helpers/base";
import classnames from "classnames";
import ReactSelect from "react-select";
import InputErrorMessage from "components/shared/InputErrorMessage";
import LabelContainer from "components/shared/LabelContainer";
import ReactSelectOption from "components/shared/ReactSelectOption";
import Loader from "./Loader";

const isOptionDisabled = (option) => option.disabled;

const ensureOptionsHaveValues = (options) => {
  return options.map((option) => {
    const newOption = option; // for the linter's no-param-reassign rule
    if (newOption.value === undefined) {
      newOption.value = newOption.label;
    }

    return newOption;
  });
};

const MultiValueRemove = () => {
  return <i className="lc-icon lc-icon-close lc-icon-close--xxs" />;
};

const MultiValueContainer = (props) => {
  const clearValue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.innerProps.onTouchEnd();
  };

  return (
    <div
      className={props.innerProps.className}
      onClick={clearValue}
      onMouseDown={clearValue}
      onTouchEnd={clearValue}
      role="presentation"
    >
      {props.children}
    </div>
  );
};

const LoadingSpinner = () => {
  return (
    <div className="loading-container">
      <Loader />
    </div>
  );
};

// Same as original but passed removeProps to the container so the container can
// also trigger a remove
const MultiValue = (props) => {
  const {
    children,
    className,
    components,
    cx,
    data,
    innerProps,
    isDisabled,
    removeProps,
    selectProps,
  } = props;

  const { Container, Label, Remove } = components;

  return (
    <Container
      data={data}
      innerProps={{
        ...innerProps,
        className: cx(
          {
            "multi-value": true,
            "multi-value--is-disabled": isDisabled,
          },
          className
        ),
        ...removeProps,
      }}
      selectProps={selectProps}
    >
      <Label
        data={data}
        innerProps={{
          className: cx(
            {
              "multi-value__label": true,
            },
            className
          ),
        }}
        selectProps={selectProps}
      >
        {children}
      </Label>
      <Remove
        data={data}
        innerProps={{
          className: cx(
            {
              "multi-value__remove": true,
            },
            className
          ),
          ...removeProps,
        }}
        selectProps={selectProps}
      />
    </Container>
  );
};

const ClearIndicator = (props) => {
  const clearValue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.clearValue();
    props.selectProps.onClear && props.selectProps.onClear();
  };

  return (
    <i
      className="lc-icon lc-icon-close lc-icon-xs lc-select__clear-indicator"
      onClick={clearValue}
      onMouseDown={clearValue}
      onTouchEnd={clearValue}
      role="presentation"
    />
  );
};

class Select extends React.Component {
  static propTypes = {
    baseClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    errorMessage: PropTypes.string,
    id: PropTypes.string,
    isControlled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isMulti: PropTypes.bool,
    isRequired: PropTypes.bool,
    isSearchable: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    preventSelect: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    components: PropTypes.object,
  };

  static defaultProps = {
    baseClassName: "lc-select-container",
    containerClassName: "select-container",
    errorMessage: null,
    isControlled: false,
    isLoading: false,
    isDisabled: false,
    isMulti: false,
    isRequired: false,
    isSearchable: false,
    options: [],
    preventSelect: false,
    value: "",
    components: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      value: ensureOptionsHaveValues(props.options).filter(
        (o) => props.value === o.value
      ),
    };
    this.selectRef = React.createRef();
  }

  componentDidMount() {
    this.replaceIndicatorIcon();
  }

  componentDidUpdate() {
    this.replaceIndicatorIcon();
  }

  replaceIndicatorIcon = () => {
    $(this.selectRef.current)
      .find(".lc-select__indicator.lc-select__dropdown-indicator svg")
      .replaceWith('<span class="lc-icon lc-icon-down lc-icon-xs" />');
  };

  onChange = (value) => {
    // preventSelect allows the select to not actually select anything but still call
    // the onChange prop
    if (!this.props.preventSelect) {
      this.setState({ value }, () => {
        $(`[name="${this.props.name}"]`).trigger("change", [value]);
      });
    }

    if (this.props.onChange && help.isFunction(this.props.onChange)) {
      this.props.onChange(value);
    }
  };

  onMenuOpen = () => {
    this.setState({ menuOpen: true });

    // Little hack to quickly move the scrollbar and make sure its displayed for a moment
    const menu = $(this.selectRef.current).find(".lc-select__menu-list");
    menu.scrollTop(1);
    menu.scrollTop(0);
  };

  onMenuClose = () => {
    this.setState({ menuOpen: false });
    const innerInput = this.selectRef.current.querySelector(
      ".lc-select__input input"
    );
    if (innerInput) {
      innerInput.blur();
    }
  };

  onFilter = (option, rawInput) => {
    const label = option.label.toLowerCase();
    const input = rawInput.toLowerCase();
    return label.includes(input);
  };

  onBlur = () => {
    // Hack for iOS, clicking the "Done" button on virtual keyboard doesn't submit the
    // highlighted value as expected, it just triggers a blur event and clears the dropdown.
    // This helper will grab the highlighted value if the user has typed something in the search
    // and trigger onChange to simulate the user selecting it before closing the menu.
    if (!this.props.isSearchable) {
      return;
    }
    const input = this.selectRef.current.querySelector("input[type=text]");
    const inputValue = input ? input.value : "";

    const selectedLabel = this.selectRef.current.querySelector(
      ".lc-select__option--is-focused .lc-select__option--inner"
    );

    if (selectedLabel) {
      const selectedValue = this.props.options.find(
        (option) => option.label === selectedLabel.innerText
      );

      if (inputValue.length > 0 && selectedValue) {
        this.onChange(selectedValue);
      }
    }
  };

  render() {
    const {
      id,
      baseClassName,
      errorMessage,
      isControlled,
      isDisabled,
      isLoading,
      isMulti,
      isSearchable,
      name,
      value,
      options,
      placeholder,
      ...labelProps
    } = this.props;

    const hasError = errorMessage !== null;
    const className = hasError
      ? `${baseClassName} lc-select-container--error`
      : baseClassName;

    const finalOptions = ensureOptionsHaveValues(options);

    // If value of select is controlled externally via the value prop, set
    // isControlled to true and the value will be that of the value prop
    // else it will be that of the internally managed state value
    let finalVal;
    if (isControlled) {
      if (isMulti) {
        finalVal = finalOptions.filter(
          (o) => this.props.value.indexOf(o.value) > -1
        );
      } else {
        finalVal = finalOptions.filter((o) => this.props.value === o.value);
      }
    } else {
      finalVal = this.state.value;
    }

    return (
      <div className={this.props.containerClassName} ref={this.selectRef}>
        <LabelContainer {...labelProps} name={name} isDisabled={false} />
        <ReactSelect
          className={classnames(className, {
            "is-searchable": isSearchable,
            "lc-menu--open": this.state.menuOpen,
          })}
          classNamePrefix="lc-select"
          components={{
            ClearIndicator,
            MultiValueContainer,
            MultiValueRemove,
            // When isLoading is false, we use the default DropdownIndicator,
            // but when isLoading is true, we remove the DropdownIndicator and replace
            // it with a LoadingSpinner
            ...(isLoading ? { DropdownIndicator: LoadingSpinner } : {}),
            MultiValue,
            Option: ReactSelectOption,
            ...this.props.components,
          }}
          inputId={id}
          isOptionDisabled={isOptionDisabled}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isSearchable={isSearchable}
          noOptionsMessage={() => "No matches found."}
          styles={{
            control: (baseStyles, state) => {
              return {
                ...baseStyles,
                borderColor:
                  state.isDisabled && isLoading && hasError
                    ? "#BB5050 !important"
                    : baseStyles.borderColor,
              };
            },
            noOptionsMessage: (baseStyles, _state) => ({
              ...baseStyles,
              color: "#181919", //gray
              textAlign: "left",
            }),
          }}
          filterOption={this.onFilter}
          menuIsOpen={this.state.menuOpen}
          name={name}
          onChange={this.onChange}
          onMenuOpen={this.onMenuOpen}
          onMenuClose={this.onMenuClose}
          onBlur={this.onBlur}
          options={finalOptions}
          placeholder={placeholder}
          value={finalVal}
        />
        {errorMessage && <InputErrorMessage message={errorMessage} />}
        {/* // ReactSelect does not render an input if the isDisabled property is set
        // we still want to render the hidden input to send the param to rails even if it's disabled */}
        {isDisabled && <input name={name} type="hidden" value={value} />}
      </div>
    );
  }
}

export default Select;
