export default class LcView {
  constructor(definition) {
    Object.assign(this, definition);
  }

  initialize() {}

  ready() {}

  static create(definition) {
    const obj = new LcView(definition);
    obj.initialize();
    return obj;
  }
}
