import LcView from "lib/shared/view";
import help from "helpers/base";
import LineMapper from "lib/poetry/line_mapper";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import selectors from "constants/poetry/poems/show/selectors";
import helpOnDemandPromo from "views/shared/help_on_demand/promo";

export default LcView.create({
  initialize() {
    this.setSelectors();
    this.LM_HOVER_CLASS = "poem-inline__lm--hover";
    this.LM_CLICKED_CLASS = "poem-inline__lm--clicked";
    this.scrollOffsetThreshold = 115;
    this.mouseX = 0;
    this.mouseY = 0;
    this.hasLoaded = false;
    this.isPublicDomain = $(this.selectors.MAIN).data("public-domain") === true;
    this.hodPromoIsRevealed = false;
    window.history.scrollRestoration = "manual"; // prevent default scroll restoration behavior
  },
  ready() {
    const $hideUntilLoaded = $(this.selectors.HIDE_UNTIL_LOADED);
    const $poemTextScrollable = $(this.selectors.TEXT_SCROLLABLE);
    const $poemTextFixed = $(this.selectors.TEXT_FIXED);
    const $poemTextModal = $(this.selectors.TEXT_MODAL);
    const $nav = $(this.selectors.NAV);
    const $mobileNav = $(this.selectors.MOBILE_NAV);
    const $mobileHeader = $(this.selectors.MOBILE_HEADER);
    const $pdfHeader = $(this.selectors.PDF_HEADER);
    const $litTermModal = $(this.selectors.MODAL_LIT_TERM);
    const $drawer = $(this.selectors.DRAWER);

    this.poemTextOffsetTop = $poemTextScrollable.offset().top;
    this.poemNavOffsetTop = $nav.offset().top;
    this.mobileHeaderOffsetTop = $mobileHeader.offset().top;
    this.pdfHeaderOffsetTop = $pdfHeader.offset().top;
    this.$currentPoemText = $poemTextScrollable;

    this.initializeListeners();
    this.setFixedPoemTextWidth();
    this.setDrawerWidth();
    this.collapseExpandMobileTitle();
    this.setContextSwitcherTop();

    setTimeout(() => {
      this.applyiOSModalScrollFix();
    }, 500); // wrapped in timeout works, suspect due to bootstrap loading 2x

    // slide in HOD promo
    setTimeout(() => {
      helpOnDemandPromo.setOffset();
      this.hodPromoIsRevealed = true;
    }, 3000);

    helpOnDemandPromo.ready();

    $litTermModal.modal("hide");
    $poemTextModal.modal("hide");
    $drawer.modal("hide");

    $poemTextFixed.hide();
    $mobileNav.hide();

    if (help.isMobile()) {
      $poemTextScrollable.hide();
    }

    this.positionPromo();

    this.loadPoem().then(() => {
      $hideUntilLoaded.css("visibility", "visible");
      this.scrollToStoredLocation();
      this.hasLoaded = true;
    });
  },

  initializeListeners() {
    window.onscroll = () => {
      this.handleScroll();
    };
    window.onresize = () => {
      this.handleResize();
    };

    $(this.selectors.CONTEXT_SWITCHER).click(() => {
      $(this.selectors.LC_TEXT_TOGGLE).trigger("click"); // switch to the tool
      $(this.selectors.AI_TEXT_TOGGLE).trigger("click"); // scroll
    });

    $(this.selectors.AI_TEXT_TOGGLE).click(() => {
      if (this.isAiSelected()) {
        this.scrollToAiTool();
      } else {
        const { scrollY } = this.getStoredScrollLocation();
        window.scrollTo(0, scrollY);
      }
    });

    $(this.selectors.MOBILE_NAV_TOGGLE).click((event) => {
      this.handleMobileNavToggle(event);
    });
    $(this.selectors.TEXT_MODAL_TOGGLE).click((event) => {
      this.handlePoemTextModalToggle(event);
    });
    $(this.selectors.NAV_LINK).click((event) => {
      this.handleNavLinkClick(event);
    });
    $(this.selectors.MOBILE_NAV_LINK).click((event) => {
      this.handleNavLinkClick(event);
    });
    $(this.selectors.HOD_PROMO_CTA).click(() => {
      this.handleHelpOnDemandPromoClick();
    });

    $(document).mousemove((event) => {
      this.handleMouseMove(event);
    });

    $(document).on(
      "touchstart click",
      this.selectors.COMPONENT_ITEM,
      (event) => {
        this.handleComponentItemClick(event);
      }
    );
    $(document).on("change", this.selectors.COMPONENT_ITEMS_SELECT, (event) => {
      this.handleComponentItemSelect(event);
    });
    $(document).on("touchstart click", this.selectors.INLINE_LINK, (event) => {
      this.handleInlineLinkClick(event);
    });
    $(document).on(
      "mouseenter",
      `${this.selectors.IS_FOCUSED} ${this.selectors.LINE_MAPS}`,
      (event) => {
        this.handleLineMapMouseEnter(event);
      }
    );
    $(document).on(
      "mouseleave",
      `${this.selectors.IS_FOCUSED} ${this.selectors.LINE_MAPS}`,
      (event) => {
        this.handleLineMapMouseLeave(event);
      }
    );
    $(document).on(
      "touchstart",
      `${this.selectors.IS_FOCUSED} ${this.selectors.LINE_MAPS}`,
      (event) => {
        this.handleLineMapTouchstart(event);
      }
    );
    $(document).on(
      "touchstart",
      `${this.selectors.IS_FOCUSED} ${this.selectors.LINE_MAP_VALUE_ONE_TO_ONE}`,
      (event) => {
        this.handleLineMapTouchstart(event);
      }
    );
    $(document).on(
      "touchend click",
      `${this.selectors.IS_FOCUSED} ${this.selectors.LINE_MAP_VALUE_ONE_TO_ONE}`,
      (event) => {
        this.handleLineMapClick(event);
      }
    );
    $(document).on(
      "touchstart click",
      `${this.selectors.IS_FOCUSED} ${this.selectors.LINE_MAP_VALUE_STRUCTURE}`,
      (event) => {
        this.handleLineMapStructureClick(event);
      }
    );
    $(document).on(
      "touchstart click",
      `${this.selectors.IS_FOCUSED} ${this.selectors.LINE_MAP_VALUE_RHYME_SCHEME}`,
      (event) => {
        this.handleLineMapRhymeSchemeClick(event);
      }
    );
  },

  getFixedHeaderHeight() {
    const $nav = $(this.selectors.NAV);
    const $mobileHeader = $(this.selectors.MOBILE_HEADER);
    const $pdfHeader = $(this.selectors.PDF_HEADER);

    let height = 0;
    height += $nav.is(":visible") ? $nav.height() : 0;
    height +=
      $mobileHeader.is(":visible") && $mobileHeader.css("position") === "fixed"
        ? $mobileHeader.height()
        : 0;
    height += $pdfHeader.height();
    return height;
  },

  // some browsers (such as firefox) need a set height for children of fixed elements instead of a percentage
  // so we figure out the height it should be based on the remaining viewing space once other elements are removed
  //
  //            #poems-show__poem-text--fixed
  //
  //   |-----------------margin-top-----------------|   <---|           <---|
  //   |-----------------padding-top----------------|       |               |
  //   |--------.poems-show__poem-text--title-------|       |               |
  //   |--------------------------------------------|       |           <---|
  //   |                                            |       |                            <---|
  //   |                                            |       |                                |
  //   |                                            |       |    minus           equals      |
  //   |         .poems-show__poem-content          |       |                                |
  //   |                                            |       |                                |
  //   |                                            |       |                                |
  //   |                                            |       |                            <---|
  //   |--------------------------------------------|       |           <---|
  //   |-----------------padding-bottom-------------|   <---|           <---|
  //
  setFixedPoemTextHeight() {
    if (help.isMobile()) {
      return;
    }
    const $poemTextFixed = $(this.selectors.TEXT_FIXED);
    const $poemContent = $poemTextFixed.find(this.selectors.POEM_CONTENT);
    const $poemTitle = $poemTextFixed.find(this.selectors.TEXT_TITLE);
    const vHeight = help.getViewportHeight();
    const height =
      vHeight -
      this.getFixedHeaderHeight() -
      (parseInt($poemTextFixed.css("paddingTop")) || 0) -
      (parseInt($poemTextFixed.css("marginTop")) || 0) -
      $poemTitle.outerHeight() -
      (parseInt($poemTextFixed.css("paddingBottom")) || 0);

    $poemContent.height(height);
  },

  setFixedPoemTextWidth() {
    const $poemTextColumn = $(this.selectors.TEXT_COLUMN);
    const $poemTextFixed = $(this.selectors.TEXT_FIXED);
    $poemTextFixed.width($poemTextColumn.width());
  },

  setFixedPoemTextTop() {
    if (help.isMobile()) {
      return;
    }
    const $document = $(document);
    const $poemTextFixed = $(this.selectors.TEXT_FIXED);
    const $poemContent = $poemTextFixed.find(this.selectors.POEM_CONTENT);
    const $poemTextTitle = $poemTextFixed.find(this.selectors.TEXT_TITLE);
    const headerHeight = this.getFixedHeaderHeight();
    const maxTop =
      $(this.selectors.FOOTER).offset().top -
      parseInt($(this.selectors.MAIN).css("paddingBottom"));

    const bottom =
      $document.scrollTop() +
      headerHeight +
      parseInt($poemTextFixed.css("marginTop")) +
      parseInt($poemTextFixed.css("paddingTop")) +
      $poemTextTitle.height() +
      $poemContent.height();
    const top = headerHeight + (bottom > maxTop ? maxTop - bottom : 0);
    $poemTextFixed.css("top", `${top}px`);
  },

  setGuideColumnTop() {
    const $guideColumn = $(this.selectors.GUIDE_COLUMN);
    const $nav = help.isMobile()
      ? $(this.selectors.MOBILE_HEADER)
      : $(this.selectors.NAV);
    const $pdfHeader = $(this.selectors.PDF_HEADER);

    const top =
      $nav.css("position") === "fixed" && $pdfHeader.css("position") === "fixed"
        ? $nav.height() + $pdfHeader.height()
        : 0;

    $guideColumn.css({ top: `${top}px`, "margin-bottom": `${top}px` });
  },

  setContextSwitcherTop() {
    const $contextSwitcher = $(this.selectors.CONTEXT_SWITCHER);
    const $mobileHeader = $(this.selectors.MOBILE_HEADER);
    $contextSwitcher.css("top", `${$mobileHeader.height()}px`);
  },

  setDrawerWidth() {
    const $drawer = $(this.selectors.DRAWER);
    const $dialog = $drawer.find(".modal-dialog");
    const width = $drawer.width();
    $dialog.width(width);
  },

  setMobileHeaderHeight() {
    const $mobileHeader = $(this.selectors.MOBILE_HEADER);
    const $mobileNav = $(this.selectors.MOBILE_NAV);

    if ($mobileNav.is(":visible")) {
      const vHeight = help.getViewportHeight();
      let height = vHeight;
      if (!$mobileHeader.hasClass("stick")) {
        height = vHeight - $mobileHeader.offset().top;
      }
      $mobileHeader.height(height);
    } else {
      $mobileHeader[0].style.height = null;
    }
  },

  setHelpOnDemandPromoRight() {
    const $container = $(this.selectors.CONTAINER);
    const $hodPromo = $(this.selectors.HOD_PROMO);
    const viewportWidth = help.getViewportWidth();
    const containerWidth = $container.outerWidth();

    if (viewportWidth > containerWidth) {
      $hodPromo.css("right", (viewportWidth - containerWidth) / 2);
    } else {
      $hodPromo.css("right", 0);
    }
  },

  positionPromo() {
    const $promo = $(this.selectors.BANNER_PROMO);

    if (!$promo.length) {
      return false;
    }

    const $main = $(this.selectors.MAIN);
    $main.prepend($promo);
  },

  applyiOSModalScrollFix() {
    if (!$("html").hasClass("ios-device")) {
      return false;
    }

    $(".modal").on("show.bs.modal", () =>
      disableBodyScroll($(this).find(".modal-dialog")[0])
    );
    $(".modal").on("hide.bs.modal", () =>
      enableBodyScroll($(this).find(".modal-dialog")[0])
    );
  },

  loadPoem() {
    return new Promise((resolve, reject) => {
      this.ajaxPoemLoad().then(
        (data) => {
          help.object.each(data, (category, components) => {
            help.array.each(components, (component) => {
              this.loadComponentData(component);
            });
          });
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });
  },

  ajaxPoemLoad() {
    const slug = $(this.selectors.MAIN).data("slug");
    return $.ajax({
      url: `/poems/${slug}/load`,
      type: "GET",
      dataType: "json",
      data: { cached: help.getQueryParam("cached") },
    });
  },

  loadComponentData(data) {
    const { category, position } = data;
    const positionAttr =
      position === undefined || position === null
        ? ""
        : `[data-position='${position}']`;
    const attrSelector = `[data-category='${category}']${positionAttr}`;
    const $component = $(this.selectors.COMPONENT + attrSelector);
    const $valueReplace = $(
      $component.find(this.selectors.COMPONENT_VALUE_REPLACE)
    );
    const $promo = $($component.find(this.selectors.PROMO));
    const $promoMain = $($component.find(this.selectors.PROMO_MAIN));
    const $promoAlt = $($component.find(this.selectors.PROMO_ALT));
    const $textLink = $($component.find(this.selectors.TEXT_LINK));
    const $activeContext = $($component.find(this.selectors.ACTIVE_CONTEXT));
    const $componentItem = $(this.selectors.COMPONENT_ITEM + attrSelector);
    const $componentOption = $(
      this.selectors.COMPONENT_ITEMS_SELECT_OPTION + attrSelector
    );

    if (data.show_promo) {
      $component.attr("data-show-promo", true);
      $textLink.hide();
      $promo.show();
      $activeContext.addClass(
        this.getClassForSelector("ACTIVE_CONTEXT_BLURRED")
      );
      switch (data.promo_type) {
        case "main":
          $promoAlt.hide();
          break;
        case "alt":
          $promoMain.hide();
          break;
        // no default
      }
    } else {
      $valueReplace.html(data.value);
    }

    $componentItem.data("value", data.value);
    $componentOption.data("value", data.value);
    this.afterLoadComponentData(category, $component, data);
  },

  afterLoadComponentData(category, $component, data) {
    switch (category) {
      case "context":
        this.afterLoadComponentContext($component, data);
        break;
      // no default
    }
  },

  afterLoadComponentContext($component, data) {
    const $section = $(`${this.selectors.SECTION}[data-section='context']`);
    const $components = $($section.find(this.selectors.COMPONENTS));

    $components.html("");
    $components.append(data.value);
    $components
      .children(".poem-inline__component--context")
      .each((i, element) => {
        const $element = $(element);
        const originalValue = $element
          .children()
          .filter(":not(h3)")
          .map((j, n) => n.outerHTML)
          .toArray()
          .join("");

        $element.addClass(this.getClassForSelector("COMPONENT"));
        $element.attr(
          "data-highlight-when-focused",
          this.isPublicDomain.toString()
        );
        $element.attr("data-category", "context");
        $element.attr("data-position", i + 1);
        $element.attr("data-content", data.content);
        $element.append(
          `<h3 class='${this.getClassForSelector("COMPONENT_TITLE")}'></h3>`
        );
        $element.append(
          `<div class='${this.getClassForSelector("COMPONENT_VALUE")}'>` +
            `<div class='${this.getClassForSelector(
              "COMPONENT_VALUE_REPLACE"
            )}'></div>` +
            "</div>"
        );

        const $title = $element.find(this.selectors.COMPONENT_TITLE);
        const $valueReplace = $element.find(
          this.selectors.COMPONENT_VALUE_REPLACE
        );
        const $value = $element.find(this.selectors.COMPONENT_VALUE);

        $title.html($element.find("h3").html());
        $valueReplace.html(originalValue);
        $element.html($title[0].outerHTML + $value[0].outerHTML);
      });
  },

  getComponents() {
    return $(this.selectors.COMPONENT).sort((a, b) => {
      const aOffset = $(a).offset().top;
      const bOffset = $(b).offset().top;
      if (aOffset > bOffset) {
        return -1;
      }
      if (aOffset < bOffset) {
        return 1;
      }
      return 0;
    });
  },

  getSelectorForLineMapId(id) {
    return `[data-lm-id='${id}']`;
  },

  getFocusedComponent() {
    const scrollTop = $(document).scrollTop();
    const $components = this.getComponents();
    const navHeight = this.getFixedHeaderHeight();
    let focusedComponent = null;

    $components.each((i, component) => {
      const $section = $(component).closest(this.selectors.SECTION);
      const $childComponents = $($section.find(this.selectors.COMPONENT));
      let offset = parseInt($section.css("marginTop")) || 0;

      if ($childComponents[0] === component) {
        offset += $(component).offset().top - $section.offset().top;
      }
      if (
        scrollTop >
        $(component).offset().top -
          navHeight -
          offset -
          this.scrollOffsetThreshold
      ) {
        focusedComponent = component;
      }
      if (focusedComponent) {
        return false;
      }
    });

    // Default to the first component if we're at the top of the page
    if (!focusedComponent) {
      focusedComponent = $components[$components.length - 1];
    }

    return $(focusedComponent);
  },

  isAiSelected() {
    const aiTool = document.querySelector(this.selectors.POEM_AI_TOOL);
    const guideContent = document.querySelector(
      this.selectors.POEM_GUIDE_CONTENT
    );
    return (
      !aiTool.classList.contains("hidden-sm") &&
      guideContent.classList.contains("hidden-sm")
    );
  },

  toggleFocusedComponent() {
    if (this.isAiSelected()) {
      return;
    }
    const $component = this.getFocusedComponent();
    this.storeScrollLocation($component);
    $component.length
      ? this.focusComponent($component)
      : this.unfocusComponent();
  },

  focusComponent($component) {
    const title = $component.data("title") || "";
    const modalTitle = $component.data("modal-title") || "";
    const category = $component.data("category");
    const content = $component.data("content");
    const section = $component.closest(this.selectors.SECTION).data("section");
    const modalCategory = modalTitle + (title ? ": " : "") + title;
    $component.addClass("is-focused");
    $(this.selectors.POEM_CONTENT).html(content);
    $(this.selectors.TEXT_MODAL_CATEGORY).text(modalCategory);
    $(this.selectors.NAV_LINK)
      .removeClass("is-active")
      .addClass("another-link-is-active");
    $(`${this.selectors.NAV_LINK}[data-target='${section}']`)
      .removeClass("another-link-is-active")
      .addClass("is-active");

    $(this.selectors.SECTION).removeClass("is-focused");
    $(`${this.selectors.SECTION}[data-section='${section}']`).addClass(
      "is-focused"
    );

    $(this.selectors.POEM_CONTENT).each((i, element) => {
      $(element).attr("data-focused-section", section);
      $(element).attr("data-focused-category", category);
    });

    this.afterFocusComponent(category, $component);
  },

  unfocusComponent() {
    const $content = $(this.selectors.POEM_CONTENT);
    $(this.selectors.COMPONENT).removeClass("is-focused");
    $(this.selectors.SECTION).removeClass("is-focused");
    $content.html($content.data("original-content"));
    $(this.selectors.NAV_LINK).removeClass("is-active");
    $(this.selectors.TEXT_MODAL_CATEGORY).text("Full Text");
  },

  unfocusAll() {
    if (this.isAiSelected()) {
      return;
    }
    $(this.selectors.COMPONENT).removeClass("is-focused");
  },

  afterFocusComponent(category, $component) {
    switch (category) {
      case "form":
        this.afterFocusComponentForm($component);
        break;
      case "context":
        this.afterFocusComponentContext($component);
        break;
      // no default
    }
  },

  afterFocusComponentForm() {
    const $lineMaps = $(
      this.$currentPoemText.find(this.selectors.LINE_MAP_CONTENT_STRUCTURE)
    );
    const ids = help.array.unique(
      $lineMaps.toArray().map((lineMap) => $(lineMap).data("lm-id"))
    );
    const $content = $(this.$currentPoemText.find(this.selectors.POEM_CONTENT));
    const lineMapper = new LineMapper("structure", 1, $content);

    help.array.each(ids, (id) => {
      lineMapper.createStructureMappingLine(id);
    });
  },

  afterFocusComponentContext($component) {
    const $lineMaps = $(
      this.$currentPoemText.find(this.selectors.LINE_MAP_CONTENT_ONE_TO_ONE)
    );
    $lineMaps.each((i, lineMap) => {
      const $lineMap = $(lineMap);
      const matches =
        $lineMap.attr("data-description") ===
        $component.attr("data-description");
      $(lineMap).attr("data-matches-description", matches);
    });
  },

  togglePoemText() {
    const $document = $(document);
    const $poemTextScrollable = $(this.selectors.TEXT_SCROLLABLE);
    const $poemTextFixed = $(this.selectors.TEXT_FIXED);

    if (help.isMobile() || !this.isPublicDomain) {
      $poemTextScrollable.hide();
      $poemTextFixed.hide();
      return;
    }

    const navHeight = this.getFixedHeaderHeight();
    this.poemTextOffsetTop = $poemTextScrollable.is(":visible")
      ? $poemTextScrollable.offset().top
      : this.poemTextOffsetTop;

    if ($document.scrollTop() > this.poemTextOffsetTop - navHeight) {
      this.fixPoemText();
    } else {
      this.unfixPoemText();
    }
  },

  fixPoemText() {
    const $poemTextScrollable = $(this.selectors.TEXT_SCROLLABLE);
    const $poemTextFixed = $(this.selectors.TEXT_FIXED);
    $poemTextScrollable.hide();
    $poemTextFixed.show();
    this.$currentPoemText = $poemTextFixed;
  },

  unfixPoemText() {
    const $poemTextScrollable = $(this.selectors.TEXT_SCROLLABLE);
    const $poemTextFixed = $(this.selectors.TEXT_FIXED);
    $poemTextScrollable.show();
    $poemTextFixed.hide();
    this.$currentPoemText = $poemTextScrollable;
  },

  handlePoemTextModalToggle() {
    const $poemTextModal = $(this.selectors.TEXT_MODAL);
    $poemTextModal.modal("show");
  },

  toggleNavStick() {
    const $document = $(document);
    const $nav = help.isMobile()
      ? $(this.selectors.MOBILE_HEADER)
      : $(this.selectors.NAV);
    const navOffsetTop = help.isMobile()
      ? this.mobileHeaderOffsetTop
      : this.poemNavOffsetTop;

    if ($document.scrollTop() >= navOffsetTop) {
      $nav.addClass("stick");
    } else {
      $nav.removeClass("stick");
    }
  },

  getPdfHeaderBoundary() {
    const $nav = help.isMobile()
      ? $(this.selectors.MOBILE_HEADER)
      : $(this.selectors.NAV);

    return help.isMobile() ? this.mobileHeaderOffsetTop : $nav.offset().top;
  },

  togglePdfHeaderStick() {
    const $document = $(document);
    const $pdfHeader = $(this.selectors.PDF_HEADER);
    const $nav = help.isMobile()
      ? $(this.selectors.MOBILE_HEADER)
      : $(this.selectors.NAV);
    const top = $nav.is(":visible") ? $nav.height() : 0;
    const pdfHeaderBoundary = this.getPdfHeaderBoundary();

    if ($document.scrollTop() >= pdfHeaderBoundary) {
      $pdfHeader.addClass("stick");
      $pdfHeader.css({ top });
    } else {
      $pdfHeader.removeClass("stick");
    }
  },

  triggerLineMapMouseToggle() {
    this.unhoverAll();
    const target = document.elementFromPoint(this.mouseX, this.mouseY);
    this.handleLineMapMouseEnter({ target });
    $(target)
      .children()
      .each((i, child) => {
        this.handleLineMapMouseEnter({ target: child });
      });
  },

  collapseExpandMobileTitle() {
    const title = document.querySelector(
      this.selectors.MOBILE_HEADER_POEM_TITLE
    );
    const mobileHeader = document.querySelector(this.selectors.MOBILE_HEADER);

    if (mobileHeader.classList.contains("stick")) {
      title.classList.add("hidden");
    } else {
      title.classList.remove("hidden");
    }
  },

  storeScrollLocation($focusedComponent) {
    if (!this.hasLoaded) {
      return;
    }
    // """
    // setItem() may throw an exception if the storage is full. Particularly, in Mobile Safari (since iOS 5) it
    // always throws when the user enters private mode. (Safari sets the quota to 0 bytes in private mode, unlike
    // other browsers, which allow storage in private mode using separate data containers.) Hence developers
    // should make sure to always catch possible exceptions from setItem().
    // """
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
    try {
      const navHeight = this.getFixedHeaderHeight();
      const scrollY = $(document).scrollTop();
      const id = this.getPoemId();
      window.localStorage.setItem(
        `litcharts-poetry-poems-show-${id}-scrollY`,
        scrollY
      );
      if ($focusedComponent.length) {
        window.localStorage.setItem(
          `litcharts-poetry-poems-show-${id}-focusedComponentCategory`,
          $focusedComponent.data("category")
        );
        window.localStorage.setItem(
          `litcharts-poetry-poems-show-${id}-focusedComponentPosition`,
          $focusedComponent.data("position")
        );
        const deltaScrollY =
          scrollY - navHeight - $focusedComponent.offset().top;
        window.localStorage.setItem(
          `litcharts-poetry-poems-show-${id}-deltaScrollY`,
          deltaScrollY
        );
      } else {
        window.localStorage.setItem(
          `litcharts-poetry-poems-show-${id}-focusedComponentCategory`,
          ""
        );
        window.localStorage.setItem(
          `litcharts-poetry-poems-show-${id}-focusedComponentPosition`,
          ""
        );
        window.localStorage.setItem(
          `litcharts-poetry-poems-show-${id}-deltaScrollY`,
          ""
        );
      }
    } catch (e) {
      console.error("Error in storeScrollLocation", e);
    }
  },

  getStoredScrollLocation() {
    const id = this.getPoemId();
    return {
      scrollY: window.localStorage.getItem(
        `litcharts-poetry-poems-show-${id}-scrollY`
      ),
      category: window.localStorage.getItem(
        `litcharts-poetry-poems-show-${id}-focusedComponentCategory`
      ),
      position: window.localStorage.getItem(
        `litcharts-poetry-poems-show-${id}-focusedComponentPosition`
      ),
      deltaScrollY: window.localStorage.getItem(
        `litcharts-poetry-poems-show-${id}-deltaScrollY`
      ),
    };
  },

  scrollToAiTool() {
    const $guideControls = $(this.selectors.AI_TEXT_TOGGLE);
    const scrollTo = help.isMobile()
      ? this.mobileHeaderOffsetTop
      : this.poemNavOffsetTop + $guideControls.height();

    window.scrollTo(0, scrollTo);
  },

  scrollToStoredLocation() {
    // test env may use this query param to prevent scroll restoration
    if (help.getQueryParam("scroll") === "false") {
      return;
    }
    const { scrollY, category, position, deltaScrollY } =
      this.getStoredScrollLocation();
    let scrollTo = 0;
    if (category) {
      const dataPosition =
        position !== "undefined" ? `[data-position='${position}']` : "";
      const selector = `${this.selectors.COMPONENT}[data-category='${category}']${dataPosition}`;
      const $component = $(selector);
      if ($component.length) {
        scrollTo = $component.offset().top + parseInt(deltaScrollY);
      } else {
        scrollTo = scrollY;
      }
    } else {
      scrollTo = scrollY;
    }
    this.scrollTo(scrollTo);
  },

  scrollTo(y) {
    $("html, body").animate({ scrollTop: y }, 200, "swing", () => {
      this.handleScroll();
    });
  },

  handleResize() {
    const $mobileHeader = $(this.selectors.MOBILE_HEADER);
    const $pdfHeader = $(this.selectors.PDF_HEADER);

    // unsticking headers to get offset, then resticking if necessary
    const mobileHeaderHasStick = $mobileHeader.hasClass("stick");
    const pdfHeaderHasStick = $pdfHeader.hasClass("stick");

    this.mobileHeaderOffsetTop = $mobileHeader
      .removeClass("stick")
      .offset().top;
    this.pdfHeaderOffsetTop = $pdfHeader.removeClass("stick").offset().top;

    $mobileHeader.toggleClass("stick", mobileHeaderHasStick);
    $pdfHeader.toggleClass("stick", pdfHeaderHasStick);

    this.togglePoemText();
    this.setFixedPoemTextHeight();
    this.setFixedPoemTextWidth();
    this.setDrawerWidth();
    this.setMobileHeaderHeight();
    this.setContextSwitcherTop();
    this.setHelpOnDemandPromoRight();
    if (this.hodPromoIsRevealed) {
      helpOnDemandPromo.setOffset();
    }
  },

  handleScroll() {
    this.togglePoemText();
    this.unfocusAll();
    this.toggleFocusedComponent();
    this.toggleNavStick();
    this.togglePdfHeaderStick();
    this.setFixedPoemTextTop();
    this.setFixedPoemTextHeight();
    this.collapseExpandMobileTitle();
    this.setGuideColumnTop();
    this.setContextSwitcherTop();
    this.triggerLineMapMouseToggle();
  },

  handleMobileNavToggle() {
    const $mobileHeader = $(this.selectors.MOBILE_HEADER);
    const $mobileNav = $(this.selectors.MOBILE_NAV);
    // save the mobile header's height before it's expanded
    if (!$mobileHeader.hasClass("is-active")) {
      $mobileHeader.attr("data-static-height", $mobileHeader.height());
    }
    $mobileNav.toggle();
    $mobileHeader.toggleClass("is-active");
    if ($mobileNav.is(":visible")) {
      disableBodyScroll($mobileNav[0]);
      $(this.selectors.HOD_PROMO).addClass("hidden");
    } else {
      enableBodyScroll($mobileNav[0]);
      $(this.selectors.HOD_PROMO).removeClass("hidden");
    }
    this.setMobileHeaderHeight();
  },

  handleNavLinkClick(event) {
    const $target = $(event.target);
    const $mobileNav = $(this.selectors.MOBILE_NAV);
    const $link = $target.closest(
      $mobileNav.is(":visible")
        ? this.selectors.MOBILE_NAV_LINK
        : this.selectors.NAV_LINK
    );
    const section = $link.data("target");

    // Short circuit if we hit the PDF link, otherwise prevent default and scroll
    if (section === "pdf") {
      return;
    }
    event.preventDefault();

    const selector = `${this.selectors.SECTION}[data-section='${section}']`;
    const $section = $(selector);
    const $firstSection = $($(this.selectors.SECTION)[0]);
    const $nav = $(this.selectors.NAV);
    const $mobileHeader = $(this.selectors.MOBILE_HEADER);
    const $contextSwitchBanner = $(this.selectors.CONTEXT_SWITCHER);
    const $guideControls = $(this.selectors.AI_TEXT_TOGGLE);

    let fixedHeaderHeight = 0;
    let fixedHeaderOffset = 0;
    let poemTextMargin = 0;

    if (help.isMobile()) {
      if (!$mobileHeader.hasClass("stick")) {
        fixedHeaderHeight += $mobileHeader.height();
      } else {
        fixedHeaderHeight = parseInt($mobileHeader.attr("data-static-height"));
      }
      fixedHeaderOffset += $contextSwitchBanner.height();
    } else {
      fixedHeaderHeight = $nav.height();
      poemTextMargin = parseInt(this.$currentPoemText.css("marginTop"));
      fixedHeaderOffset += $guideControls.height();
    }

    if (this.isAiSelected()) {
      $(this.selectors.LC_TEXT_TOGGLE).trigger("click");
      $(this.selectors.AI_TEXT_TOGGLE).trigger("click");
    }

    const scrollTo =
      $section.offset().top -
      fixedHeaderHeight -
      fixedHeaderOffset -
      poemTextMargin -
      2 * parseInt($firstSection.css("borderBottomWidth"));

    if ($mobileNav.is(":visible")) {
      this.handleMobileNavToggle(event);
    }

    this.scrollTo(scrollTo);
  },

  handleInlineLinkClick(event) {
    event.stopPropagation();
    const $target = $(event.target);
    const $link = $($target.closest(".poem-inline__link"));
    const linkType = $link.data("link-type");

    if (linkType === "lit-term") {
      event.preventDefault();
      if ($link.data("published")) {
        $(this.selectors.MODAL_LIT_TERM).modal("show");
        $(this.selectors.MODAL_LIT_TERM_TITLE).text(
          `Definition of ${$link.attr("data-name")}`
        );
        $(this.selectors.MODAL_LIT_TERM_DEFINITION).html(
          $link.attr("data-definition")
        );
        $(this.selectors.MODAL_LIT_TERM_LINK).attr("href", $link.attr("href"));
      }
    } else if (linkType === "poem") {
      if (!$link.data("published")) {
        event.preventDefault();
      }
    }
  },

  handleHelpOnDemandPromoClick() {},

  handleComponentItemClick(event) {
    event.preventDefault();
    const $target = $(event.target);
    const category = $target.data("category");
    $(
      `${this.selectors.COMPONENT_ITEM}[data-category='${category}']`
    ).removeClass("is-active");
    $target.addClass("is-active");
    this.selectComponentItem($target);
  },

  handleComponentItemSelect(event) {
    const $target = $(event.target);
    const value = $target.val();
    const $option = $target.find(`[data-title='${value}']`);
    $target
      .children(this.selectors.COMPONENT_ITEMS_SELECT_OPTION)
      .removeClass("selected");
    $option.addClass("selected");
    this.selectComponentItem($option);
  },

  selectComponentItem($item) {
    const id = $item.data("id");
    const title = $item.data("title");
    const modalTitle = $item.data("modal-title");
    const modalCategory = modalTitle + (title ? ": " : "") + title;
    const category = $item.data("category");
    const position = $item.data("position");
    const value = $item.data("value");
    const activeContext = $item.data("active-context");
    const selector = `${this.selectors.COMPONENT}[data-category='${category}']`;
    const $component = $(selector);
    const $section = $component.closest(this.selectors.SECTION);
    if (
      help.isMobile() ||
      $section.hasClass("is-focused") ||
      !this.isPublicDomain
    ) {
      const $title = $($component.find(this.selectors.COMPONENT_TITLE));
      const $valueReplace = $(
        $component.find(this.selectors.COMPONENT_VALUE_REPLACE)
      );
      const $activeContext = $(
        $component.find(this.selectors.ACTIVE_CONTEXT_NOTE)
      );
      const $content = $(this.selectors.POEM_CONTENT);
      const $modalCategory = $(this.selectors.TEXT_MODAL_CATEGORY);

      $component.data("title", title);
      $component.data("modal-title", modalTitle);
      $component.data("category", category);
      $component.data("position", position);
      $component.data("active-context", activeContext);
      $title.text(title);
      this.setComponentContentViaAjax(id, $content, $component);
      $modalCategory.text(modalCategory);
      $activeContext.html(activeContext);
      $valueReplace.html(value);
    }
  },

  setComponentContentViaAjax(componentId, el, component) {
    $.ajax({
      url: `/poetry/poem_components/${componentId}`,
      type: "GET",
      dataType: "json",
      success(data) {
        el.html(data.content);
        component.data("content", data.content);
      },
    });
  },

  hoverAll() {
    const $highlights = $(":hover");
    $highlights.addClass(this.LM_HOVER_CLASS);
  },

  unhoverAll() {
    const selector = $(`.${this.LM_HOVER_CLASS}`);
    const $highlights = $(selector);
    $highlights.removeClass(this.LM_HOVER_CLASS);
  },

  handleMouseMove(event) {
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;
  },

  handleLineMapMouseEnter(event) {
    this.handleLineMapMouseToggle(event, true);
  },

  handleLineMapMouseLeave(event) {
    this.handleLineMapMouseToggle(event, false);
  },

  handleLineMapMouseToggle(event, add = true) {
    if (help.isMobile()) {
      return;
    }
    const $target = $(event.currentTarget);
    if ($target.is(this.selectors.LINE_MAP_CONTENT_STRUCTURE)) {
      return;
    }
    if ($target.data("description") && !$target.data("matches-description")) {
      return;
    }
    const id = $target.data("lm-id");
    const selector = this.getSelectorForLineMapId(id);
    const className = this.LM_HOVER_CLASS;
    const $lineMaps = $(selector);

    add ? $lineMaps.addClass(className) : $lineMaps.removeClass(className);
  },

  handleLineMapTouchstart(event) {
    this.lineMapTouchstart = event.target;
    this.lineMapTouchstartTimeout = setTimeout(() => {
      this.lineMapTouchstart = null;
    }, 50);

    // this is primarily for tablets, otherwise the linemapping is handled by mouseenter/mouseleave
    if (this.prevLineMapTouchStartEvent) {
      this.handleLineMapMouseLeave(this.prevLineMapTouchStartEvent);
    }
    this.prevLineMapTouchStartEvent = event;
    this.handleLineMapMouseEnter(event);
  },

  handleLineMapClick(event) {
    if (!this.isPublicDomain) {
      return;
    } // ignore line mapping if not public domain poem
    const $target = $(event.target);

    if (event.type === "touchend") {
      if (!this.lineMapTouchstart || this.lineMapTouchstart !== event.target) {
        return null;
      }

      clearTimeout(this.lineMapTouchstartTimeout);
    }

    if ($target.closest(".poem-inline__link").length) {
      return;
    } // if a line mapping is inside a link, ignore line mapping click
    const $component = $target.closest(this.selectors.COMPONENT);
    if (help.isMobile() && $component[0].hasAttribute("data-toggle-drawer")) {
      const title =
        `Original text: ${$target.data("lm-lines").toLowerCase()}` ||
        $component.data("category");
      const text = $target.data("lm-text");
      $(this.selectors.DRAWER_TITLE).text(title);
      $(this.selectors.DRAWER_CONTENT).html(text);
      $(this.selectors.DRAWER).attr("data-color", $target.data("color"));
      $(this.selectors.DRAWER).modal("show");

      const lmId = $target.data("lm-id");
      const selector = this.getSelectorForLineMapId(lmId);
      $(selector).addClass(this.LM_CLICKED_CLASS);

      $(this.selectors.DRAWER).one("hide.bs.modal", (e) => {
        this.handleDrawerDismiss(e);
      });
    }
  },

  handleDrawerDismiss() {
    $(`.${this.LM_CLICKED_CLASS}`).removeClass(this.LM_CLICKED_CLASS);
  },

  handleLineMapStructureClick() {
    if (!help.isMobile()) {
      return;
    }
    const $poemTextModal = $(this.selectors.TEXT_MODAL);
    $poemTextModal.modal("show");
    $poemTextModal.one("shown.bs.modal", () => {
      // TODO: DRY
      const $currentPoemText = $(this.selectors.TEXT_MODAL);
      const $lineMaps = $(
        $currentPoemText.find(this.selectors.LINE_MAP_CONTENT_STRUCTURE)
      );
      const ids = help.array.unique(
        $lineMaps.toArray().map((lineMap) => $(lineMap).data("lm-id"))
      );
      const $content = $($currentPoemText.find(this.selectors.POEM_CONTENT));
      const lineMapper = new LineMapper("structure", 1, $content);

      help.array.each(ids, (id) => {
        lineMapper.createStructureMappingLine(id);
      });
    });
  },

  handleLineMapRhymeSchemeClick() {
    if (!help.isMobile()) {
      return;
    }
    const $poemTextModal = $(this.selectors.TEXT_MODAL);
    $poemTextModal.modal("show");
  },

  getClassForSelector(selectorName) {
    return this.selectors[selectorName].substring(1);
  },

  getPoemId() {
    const $main = $(this.selectors.MAIN);
    return $main.data("id");
  },

  setSelectors() {
    this.selectors = selectors;
  },
});
