export default {
  ready() {
    $(".hod-promo").on("hod:reposition", () => {
      this.setOffset();
    });
  },

  setOffset() {
    const $hodPromo = $(".hod-promo");
    const $cookieFooter = $(".cookie-notification");
    const bottom = $cookieFooter.is(":visible")
      ? $cookieFooter.outerHeight()
      : 0;
    $hodPromo.css("bottom", bottom);
  },
};
