import React from "react";
import PropTypes from "prop-types";

const hiddenClass = "cookie-opt-in--hidden";

class CookieOptIn extends React.Component {
  static propTypes = {
    optInUrl: PropTypes.string,
    cookiePolicyUrl: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    termsOfServiceUrl: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this._main = React.createRef();
    this.accept = this.accept.bind(this);
  }

  componentDidMount() {
    // SHOW_COOKIE_OPT_IN will be true/false if using quantcast
    if (window.SHOW_COOKIE_OPT_IN === undefined) {
      this.show();
      return true;
    }

    this.showInterval = setInterval(() => {
      if (window.SHOW_COOKIE_OPT_IN) {
        this.show();
      } else {
        this.hide();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.showInterval);
  }

  show() {
    const $main = $(this._main.current);
    if ($main.hasClass(hiddenClass)) {
      $main.removeClass(hiddenClass);
    }
  }

  hide() {
    const $main = $(this._main.current);
    if (!$main.hasClass(hiddenClass)) {
      $main.addClass(hiddenClass);
    }
  }

  accept() {
    const $main = $(this._main.current);
    clearInterval(this.showInterval);
    $.ajax({
      url: this.props.optInUrl,
      method: "post",
      success(_data, _textStatus, _jqXHR) {
        $(".hod-promo").trigger("hod:reposition");
        $main.addClass(hiddenClass);
        LitCharts.revealUserIamaDialogAfter(120000);
      },
    });
  }

  render() {
    const { cookiePolicyUrl, privacyPolicyUrl, termsOfServiceUrl } = this.props;

    return (
      <div
        className={`cookie-opt-in ${hiddenClass}`}
        tabIndex="-1"
        role="dialog"
        ref={this._main}
      >
        <div className="cookie-opt-in__content">
          <div
            className="cookie-opt-in__header body-2"
            style={{ "font-weight": "bold" }}
          >
            Cookies
          </div>
          <div className="cookie-opt-in__body subtitle-1">
            <span>
              LitCharts uses cookies to personalize our services. By using our
              site, you acknowledge that you have read and understand our{" "}
            </span>
            <a href={cookiePolicyUrl}>Cookie Policy</a>
            <span>, </span>
            <a href={privacyPolicyUrl}>Privacy Policy</a>
            <span>, and </span>
            <a href={termsOfServiceUrl}>Terms of Service</a>.
          </div>
          <div className="cookie-opt-in__footer">
            <button
              className="btn btn-md btn-block btn-default cookie-opt-in__accept"
              onClick={this.accept}
              type="button"
            >
              Accept Cookies
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default CookieOptIn;
