import classnames from "classnames";
import React, { useEffect, useState } from "react";

function AppBanner() {
  const [content, _setContent] = useState(null);
  const [htmlContent, setHtmlContent] = useState(null);

  const clear = () => {
    setHtmlContent(null);
    _setContent(null);
  };

  const setContent = (event) => {
    setHtmlContent(null);
    _setContent(event.content);
  };

  // Progressively enhance an existing server-side generated banner by replacing it with this component. We do this
  // for JS fault tolerance, so error messages generated server-side on form submission response (e.g. sign-in form)
  // continue to work for clients with broken, disabled or download-impaired JavaScript. We do not want to be dependent
  // on JS execution for showing these error messages.
  //
  // To use this, the banner to be replaced needs the attribute: data-progressively-enhance-with='AppBanner'
  const progressivelyEnhanceExistingBanner = () => {
    const bannerToReplace = document.querySelector(
      "[data-progressively-enhance-with='AppBanner']"
    );
    if (bannerToReplace) {
      bannerToReplace.style.display = "none";
      setHtmlContent(bannerToReplace.innerHTML);
    }
  };

  useEffect(() => {
    progressivelyEnhanceExistingBanner();
    $(document).on("AppBanner:setContent", setContent);
    $(document).on("AppBanner:clear", clear);

    // Returning useEffect cleanup function to remove any event listeners.
    return () => {
      $(document).off("AppBanner:clear", clear);
      $(document).off("AppBanner:setContent", setContent);
    };
  }, []); // Empty dependencies array so this runs only once when component mounts.

  if (!content && !htmlContent) return null;

  const classes = classnames(
    "app-banner",
    "lc-banner",
    "lc-banner-warning",
    "lc-banner-section"
  );

  if (content) {
    return <div className={classes}>{content}</div>;
  }

  if (htmlContent) {
    return (
      <div
        className={classes}
        dangerouslySetInnerHTML={{ __html: htmlContent }} // eslint-disable-line react/no-danger
      />
    );
  }
}

AppBanner.clear = () => {
  $(document).trigger({ type: "AppBanner:clear" });
};

AppBanner.setContent = (content) => {
  $(document).trigger({ type: "AppBanner:setContent", content });
};

export default AppBanner;
