import AsyncQueue from "helpers/AsyncQueue";
import LcView from "lib/shared/view";

// Updates a backend request object via API
async function updateRequest(requestData) {
  const { id, attribute, value } = requestData;

  await $.ajax({
    data: {
      request: { [attribute]: value },
    },
    method: "PATCH",
    url: `requests/${id}`,
  });
}

// Fetches a list of request objects, builds the appropriate html structure
// based off .backend-requests__request-template, and appends to the page
async function getFilteredRequests(filter) {
  await $.get("requests.json", filter, (data) => {
    const $list = $(".backend-requests__request-list");
    $list.html("");

    data.forEach((request) => {
      const clone = $(
        ".backend-requests__request-template .backend-requests__request"
      ).clone(true);

      clone.attr("data-request-id", request.id);
      clone.attr("data-requesters", request.requesterData);
      clone
        .find(".backend-requests__editors-note-modal-link")
        .attr("data-content", request.editorNote);

      clone.find(".backend-requests__request-type").val(request.requestType);
      clone.find(".backend-requests__request-title").val(request.title);
      clone.find(".backend-requests__request-author").val(request.author);
      clone
        .find(".backend-requests__request-created-at")
        .html(request.formattedCreatedAt);

      clone
        .find(".backend-requests__request-search-volume-title")
        .val(request.searchVolumeTitle);
      clone.find(".backend-requests__request-source").val(request.source);
      clone.find(".backend-requests__request-length").val(request.length);
      clone
        .find(".backend-requests__request-vote-count")
        .html(request.voteCount);

      clone
        .find(".backend-requests__editors-note-modal-link")
        .html(`Editor's Note (${request.editorNoteCount})`);
      clone
        .find(".backend-requests__requester-comments-modal-link")
        .html(`Requester Comments (${request.commentCount})`);
      clone
        .find(".backend-requests__requesters-modal-link")
        .html(request.requesterCount);

      showCorrectSubtypes(clone, request.requestType);

      if (request.approved) {
        clone.find(".backend-requests__request-approved").prop("checked", true);
        clone.addClass("backend-requests__request--approved");
      }

      if (request.published) {
        clone
          .find(".backend-requests__request-published")
          .prop("checked", true);
        clone.find("input, select").prop("disabled", true);
        clone
          .find(".backend-requests__merge-request-link")
          .parent()
          .html("Merge");
        clone
          .find(".backend-requests__delete-request-link")
          .parent()
          .html("Delete");
      }

      if (request.requestType === "literature_guide") {
        clone
          .find(
            ".backend-requests__lit-guide-sub-types .backend-requests__request-sub-type"
          )
          .val(request.subType);
      } else {
        clone
          .find(
            ".backend-requests__poetry-guide-sub-types .backend-requests__request-sub-type"
          )
          .val(request.subType);
      }

      $list.append(clone);
    });

    // Apply chosen all at once after filtering is done
    $(
      ".backend-requests__request-list .backend-requests__lit-guide-sub-types .backend-requests__request-sub-type"
    )
      .not(":disabled")
      .chosen();
  });
}

// Shows correct subtype dropdowns based on the request type
function showCorrectSubtypes($request, value) {
  if (value === "literature_guide") {
    $request
      .find(".backend-requests__lit-guide-sub-types")
      .removeClass("hidden");
    $request
      .find(".backend-requests__poetry-guide-sub-types")
      .addClass("hidden");
  } else {
    $request.find(".backend-requests__lit-guide-sub-types").addClass("hidden");
    $request
      .find(".backend-requests__poetry-guide-sub-types")
      .removeClass("hidden");
  }
}

export default LcView.create({
  ready: () => {
    const updateQueue = new AsyncQueue();

    const $deleteModal = $(".backend-requests__delete-request-modal");
    const $newRequestModal = $(".backend-requests__new-request-modal");
    const $editorsNoteModal = $(".backend-requests__editor-note-modal");
    const $editorsNoteEdit = $editorsNoteModal.find(
      ".backend-requests__editor-note-edit"
    );
    const $mergeModal = $(".backend-requests__merge-request-modal");
    const $requestersModal = $(".backend-requests__requesters-modal");
    const $requesterCommentsModal = $(
      ".backend-requests__requester-comments-modal"
    );

    let filter = {
      textSearch: "",
      filterApproved: false,
      approved: null,
      filterPublished: false,
      published: null,
      sortBy: "created_at",
    };

    function updateFilter(updatedParams, opts = {}) {
      filter = {
        ...filter,
        ...updatedParams,
      };

      const { forcePush = false } = opts;

      if (forcePush) {
        updateQueue.forcePush(getFilteredRequests, filter);
      } else {
        updateQueue.push(getFilteredRequests, filter);
      }
    }

    // Bind filter text search. Uses forcePush option of asyncQueue
    // in order to prevent multiple searches queueing up
    $(".backend-requests__filter").on("input", function () {
      const $this = $(this);
      const val = $this.val();

      updateFilter({ textSearch: val }, { forcePush: true });
    });

    // Binds filter of selected request types by approved/publish from the
    // select dropdown
    $(".backend-requests__select-filter").change(function () {
      const selectedValues = $(this).val();
      let filterApproved = false;
      let approved = null;

      let filterPublished = false;
      let published = null;

      if (selectedValues.indexOf("unapproved") < 0) {
        filterApproved = true;
        approved = true;
      }

      if (selectedValues.indexOf("approved") < 0) {
        filterApproved = true;
        approved = approved ? null : false;
      }

      if (selectedValues.indexOf("unpublished") < 0) {
        filterPublished = true;
        published = true;
      }

      if (selectedValues.indexOf("published") < 0) {
        filterPublished = true;
        published = published ? null : false;
      }

      updateFilter({
        approved,
        published,
        filterApproved,
        filterPublished,
      });
    });

    // Binds correct url to each respective lit/poetry csv downloads, applying
    // the current filter options
    $(".backend-requests__download").click(function (e) {
      let filterStr = "";
      Object.keys(filter).forEach((key) => {
        filterStr += `&${key}=${filter[key]}`;
      });

      const requestType = $(this).data("requestType");

      e.originalEvent.currentTarget.href = `requests.csv?requestType=${requestType}_guide${filterStr}`;
    });

    // Bind type select in create new request modal
    $(".backend-requests__type-select").change(function () {
      if (this.value === "literature_guide") {
        $(".backend-requests__dynamic-field").html(
          $(".backend-requests__lit-guide-fields").html()
        );
        $("#request_sub_type").chosen();
      } else if (this.value === "poetry_guide") {
        $(".backend-requests__dynamic-field").html(
          $(".backend-requests__poetry-guide-fields").html()
        );
      }
    });

    // Attach errors if any on create new request from modal or just submit if no errors
    $(".backend-requests__submit-create-form").click(async (e) => {
      e.preventDefault();

      const errors = await getFormErrors();

      $(".backend-requests__error-field").removeClass(
        "backend-requests__error-field"
      );
      $(".backend-requests__new-request-form-errors").html("");

      if (!errors) {
        $(".backend-requests__new-request-form").submit();
      } else {
        Object.keys(errors).forEach((err) => {
          const $field = $(
            `.backend-requests__new-request-form [name="request[${err}]"]`
          );
          $field.addClass("backend-requests__error-field");

          if (err === "base") {
            $(".backend-requests__new-request-form-errors").append(
              `<li>${errors[err]}</li>`
            );
          } else {
            $(".backend-requests__new-request-form-errors").append(
              `<li>${err}: ${errors[err]}</li>`
            );
          }
        });
      }
    });

    $(".backend-requests__select-sort").on("change", function () {
      const val = $(this).val();

      updateFilter({ sortBy: val });
    });

    // Validate create form via backend
    async function getFormErrors() {
      const data = $(".backend-requests__new-request-form").serialize();

      try {
        await $.ajax({
          data,
          url: "requests/validate",
          type: "GET",
        });

        return false;
      } catch (e) {
        return e.responseJSON;
      }
    }

    // Queues up an update action whenever an input is typed in to immediately
    // update a request object's data as the admin is typing
    $(".backend-requests__request input").on("input", function () {
      const $this = $(this);
      const attribute = $this.attr("name");
      const id = $this.closest(".backend-requests__request").data("request-id");
      const value = $this.val();

      updateQueue.push(updateRequest, {
        attribute,
        id,
        value,
      });
    });

    // Queues up an update action for a checkbox
    $(".backend-requests__request input:checkbox").on("change", function () {
      const $this = $(this);
      const attribute = $this.attr("name");
      const $request = $this.closest(".backend-requests__request");
      const id = $request.data("request-id");
      const $unapprovedCount = $(".backend-requests__unapproved-count");
      const value = $this.prop("checked");

      updateQueue.push(updateRequest, {
        attribute,
        id,
        value,
      });

      // Approved requests have a different background color
      if (attribute === "approved") {
        value
          ? $request.addClass("backend-requests__request--approved")
          : $request.removeClass("backend-requests__request--approved");
        $unapprovedCount.text(
          parseInt($unapprovedCount.text()) + (value ? -1 : 1)
        );
      }
    });

    // Queues up an update action for a select dropdown
    $(".backend-requests__request select").on("change", function () {
      const $this = $(this);
      const attribute = $this.attr("name");
      const $request = $this.closest(".backend-requests__request");
      const id = $request.data("request-id");
      const value = $this.val();

      updateQueue.push(updateRequest, {
        attribute,
        id,
        value,
      });

      // on request type change, sub_type and length are cleared and the sub_type
      // dropdowns need to be updated to match the new request type
      if (attribute === "request_type") {
        $request.find(".backend-requests__request-length").val("");
        showCorrectSubtypes($request, value);
      }
    });

    // Queues up an update when the editor's note textfield is updated
    $editorsNoteEdit.on("input", function () {
      const $this = $(this);
      const attribute = "editor_note";
      const id = $this.data("request-id");
      const value = $this.val();

      const $editorNoteText = $(
        `.backend-requests__request[data-request-id=${id}] .backend-requests__editors-note-modal-link`
      );
      if (value === "") {
        $editorNoteText.html("Editor's Note (0)");
      } else {
        $editorNoteText.html("Editor's Note (1)");
      }

      $editorNoteText.data("content", value);

      updateQueue.push(updateRequest, {
        attribute,
        id,
        value,
      });
    });

    // Allow submitting and sets clarification text on merge modal
    $mergeModal.on("change", ".backend-requests__merge-select", function () {
      $mergeModal
        .find(".backend-requests__merge-modal-confirm")
        .attr("disabled", false);
      $mergeModal
        .find(".backend-requests__merge-request-target")
        .html($(this).find("option:selected").text());
    });

    // Show confirmation for merge modal
    $mergeModal.find(".backend-requests__merge-modal-confirm").click((e) => {
      // jquery-ujs tries to submit the form when this button is pressed so we
      // prevent it
      e.preventDefault();
      $mergeModal.find(".step-1").hide();
      $mergeModal.find(".step-2").show();
    });

    //
    // Modal binding
    //
    // All of the modals on this page are shared between all the rendered requests
    // so each modal dynamically loads its HTML on open based on the request that
    // opened it, and clears it's dynamic HTML on close as cleanup

    $(".backend-requests__delete-request-link").click(function (e) {
      e.preventDefault();

      const $this = $(this);
      const $request = $this.closest(".backend-requests__request");

      const title =
        $request.find(".backend-requests__request-title").val() || "(BLANK)";
      const author =
        $request.find(".backend-requests__request-author").val() || "(BLANK)";

      $deleteModal
        .find("form")
        .attr("action", `/admin/requests/${$request.data("request-id")}`);
      $deleteModal
        .find(".backend-requests__delete-request-info")
        .html(`${title} by ${author}`);

      $deleteModal.modal("show");
    });

    $(".backend-requests__editors-note-modal-link").click(function (e) {
      e.preventDefault();

      const $this = $(this);
      const $request = $this.closest(".backend-requests__request");
      const id = $request.data("request-id");
      const $editorNoteText = $(
        `.backend-requests__request[data-request-id=${id}] .backend-requests__editors-note-modal-link`
      );

      $editorsNoteEdit.data("request-id", id);
      $editorsNoteEdit.val($editorNoteText.data("content"));
      $editorsNoteModal.modal("show");
    });

    $(".backend-requests__merge-request-link").click(function (e) {
      e.preventDefault();

      const $this = $(this);
      const $request = $this.closest(".backend-requests__request");
      const id = $request.data("request-id");

      $mergeModal.data("request-id", id);

      const title =
        $request.find(".backend-requests__request-title").val() || "(BLANK)";
      const author =
        $request.find(".backend-requests__request-author").val() || "(BLANK)";

      let action = $mergeModal.find("form").attr("action");
      action = action.replace("ID", id);
      $mergeModal.find("form").attr("action", action);

      $mergeModal.find(".backend-requests__merge-request-title").html(title);
      $mergeModal.find(".backend-requests__merge-request-author").html(author);

      // Prevent merging a request into itself
      $(`#merge_target option[value="${id}"]`).attr("disabled", "disabled");
      $("#merge_target").trigger("chosen:updated");

      $mergeModal.modal("show");
    });

    $(".backend-requests__requesters-modal-link").click(function (e) {
      e.preventDefault();

      let requesterData = $(this)
        .closest(".backend-requests__request")
        .data("requesters");

      if (requesterData.constructor === String) {
        requesterData = JSON.parse(requesterData);
      }

      const $tbody = $(".backend-requests__requesters-table tbody");

      requesterData.forEach((requester) => {
        $tbody.append(`
          <tr>
            <td>${requester.username}</td>
            <td>${requester.email}</td>
            <td>${requester.iama}</td>
            <td>${requester.subscription}</td>
          </tr>
        `);
      });

      $requestersModal.modal("show");
    });

    $(".backend-requests__requester-comments-modal-link").click(function (e) {
      e.preventDefault();

      let requesterData = $(this)
        .closest(".backend-requests__request")
        .data("requesters");

      if (requesterData.constructor === String) {
        requesterData = JSON.parse(requesterData);
      }

      const $tbody = $(".backend-requests__requester-comments");

      requesterData
        .filter((requester) => requester.comment)
        .forEach((requester) => {
          $tbody.append(`
          <div class="backend-requests__requester-comment">
            <div>
              ${requester.username} / ${requester.email}
            </div>
            <div class="backend-requests__requester-comment-text">
              ${requester.comment}
            </div>
          </tr>
        `);
        });

      $requesterCommentsModal.modal("show");
    });

    //
    // Reset shared modals on hide
    //
    $newRequestModal.on("hidden.bs.modal", () => {
      $(".backend-requests__new-request-form").trigger("reset");
      $(".backend-requests__dynamic-field").html("");

      $(".backend-requests__error-field").removeClass(
        "backend-requests__error-field"
      );
      $(".backend-requests__new-request-form-errors").html("");
    });

    $deleteModal.on("hidden.bs.modal", () => {
      $deleteModal.find("form").attr("action", "/admin/requests/");
      $deleteModal.find(".backend-requests__delete-request-info").html("");
    });

    $editorsNoteModal.on("hidden.bs.modal", () => {
      $editorsNoteEdit.data("request-id", null);
      $editorsNoteEdit.val("");
    });

    $mergeModal.on("hidden.bs.modal", () => {
      let action = $mergeModal.find("form").attr("action");
      action = action.replace($mergeModal.data("request-id"), "ID");
      $mergeModal.find("form").attr("action", action);

      $mergeModal.data("request-id", null);

      $("#merge_target option").removeAttr("disabled");
      $("#merge_target").val(null).trigger("chosen:updated");

      $mergeModal.find(".step-1").show();
      $mergeModal.find(".step-2").hide();

      $mergeModal
        .find(".backend-requests__merge-modal-confirm")
        .attr("disabled", true);

      $mergeModal.find(".backend-requests__merge-request-title").html("");
      $mergeModal.find(".backend-requests__merge-request-author").html("");
    });

    $requestersModal.on("hidden.bs.modal", () => {
      $(".backend-requests__requesters-table tbody").html("");
    });

    $requesterCommentsModal.on("hidden.bs.modal", () => {
      $(".backend-requests__requester-comments").html("");
    });

    const $header = $(".backend-requests__header");
    const $list = $(".backend-requests__request-list");
    const headerOriginalOffsetTop = $header.offset().top;
    const listOriginalMarginTop = parseInt($list.css("marginTop"));

    window.onscroll = function () {
      if ($(document).scrollTop() > headerOriginalOffsetTop) {
        $header.addClass("backend-requests__header--fixed");
        $list.css("marginTop", `${$header.height() + listOriginalMarginTop}px`);
      } else {
        $header.removeClass("backend-requests__header--fixed");
        $list.css("marginTop", listOriginalMarginTop);
      }
    };
  },
});
