import "core-js/stable";
import "regenerator-runtime/runtime";
import "jquery";
import "lib/shared/rails_ujs_extensions";
import "jquery-ujs";
import "bootstrap-sass";
import "intersection-observer"; // Polyfill for IntersectionObserver

import ReactRailsUJS from "react_ujs";

// Expose for use in the Sprockets asset pipeline.
import "helpers/base";

export const initializePack = ({ initializers, components }) => {
  initializers.forEach((initializer) => initializer.initialize());

  ReactRailsUJS.useContext(components);
};
