import LcView from "lib/shared/view";
import qAndAiChannel from "channels/q_and_ai_channel";

export default LcView.create({
  ready() {
    $("body").on("submit", "form#prototype-form", function () {
      $.ajax({
        type: "POST",
        url: $(this).attr("action"),
        data: $(this).serialize(),
        dataType: "json",
        beforeSend() {
          $("#result-indicator").html("Submitted. Please wait.");
          $("#chunk-area").html("");
          $("#response-area").html("");
          $("#prompt-area").html("");
        },
        success(data, _status, _settings) {
          const finalPrompt = data.final_prompt;
          const chunks = data.included_chunks;

          $("#prompt-area").text(finalPrompt);

          $.each(chunks, function (i, chunk) {
            $("#chunk-area").append(
              `<tr style='border-bottom: 1px solid black;'><td>${chunk.body}</td><td>${chunk.similarity}</td></tr>`
            );
          });
        },
        error(data, status, _settings) {
          if (status === "timeout") {
            $("#result-indicator").html("Timed out.");
          } else {
            $("#result-indicator").html(data.responseText);
          }
        },
      });
      return false;
    });
    qAndAiChannel();
  },
});
