class LcOAuth2 {
  static initGooglePromise;

  static googleAuthButtonLastClicked;

  // Loads and initializes the Google Identity Services Library. This function can be called multiple times, although
  // the actual load and initialization will only happen once, as recommended by Google.
  //
  // Usage:
  // LcOAuth2.initGoogle().then(() => { console.log('Google lib is initialized'}; )
  static initGoogle() {
    if (LcOAuth2.initGooglePromise) {
      // Initialization has already begun, return the promise.
      return LcOAuth2.initGooglePromise;
    }

    // Loads the Google Library by appending a <script> tag to <body>.
    const loadScript = () =>
      new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.id = "google-id-lib";
        script.src = window.LitCharts.googleIdLibSrc;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          resolve();
        };
        script.onerror = () => {
          reject();
        };
        document.body.appendChild(script);
      });

    // Google advises waiting for document to be loaded before calling google.accounts.id.initialize
    const onDocumentLoaded = () =>
      new Promise((resolve, _reject) => {
        if (document.readyState === "complete") {
          // Load event already fired, so resolve immediately.
          resolve();
        } else {
          // Load event not yet fired, add event listener.
          window.addEventListener("load", resolve);
        }
      });

    LcOAuth2.initGooglePromise = loadScript()
      .then(onDocumentLoaded)
      .then(() => {
        const dispatchCredentialResponseEvent = (response) => {
          // select_by describes how the credential was selected.
          // If select_by starts with "user" "itp", or "fedcm" then the user clicked from the One Tap prompt.
          // If select_by starts with "btn", then the user clicked a GoogleAuthButton.
          // https://developers.google.com/identity/gsi/web/reference/js-reference#select_by
          //
          // Jan 22, 2024 Note: Google is switching over to FedCM (details below)
          // and consequently we will need to check for it.
          // https://developers.google.com/identity/gsi/web/guides/fedcm-migration
          console.log(
            `Google Auth: Attempting login via '${response.select_by}'`
          );
          const originatedFromOneTap =
            response.select_by.startsWith("user") ||
            response.select_by.startsWith("fedcm") ||
            response.select_by.startsWith("itp");
          const originatedFromButton = response.select_by.startsWith("btn");
          if (originatedFromOneTap) {
            $(document).trigger({
              type: "googleIdOneTap:credentialResponse",
              response,
            });
          } else if (originatedFromButton) {
            $(LcOAuth2.googleAuthButtonLastClicked).trigger({
              type: "googleIdButton:credentialResponse",
              response,
            });
          } else {
            console.error(
              `Google Auth: Unable to respond to ${response.select_by}`
            );
          }
        };

        google.accounts.id.initialize({
          client_id: window.LitCharts.googleClientId,
          callback: dispatchCredentialResponseEvent,
          // Do not close the One Tap widget if a user clicks outside the widget (cancel_on_tap_outside == false). They
          // must click the close (X) icon.
          // https://developers.google.com/identity/gsi/web/reference/js-reference#cancel_on_tap_outside
          cancel_on_tap_outside: false,
        });
      });
    return LcOAuth2.initGooglePromise;
  }
}

export default LcOAuth2;
