import LcView from "lib/shared/view";

export default LcView.create({
  ready: () => {
    const $newCollectionModal = $(".backend-collections__new-collection-modal");

    $newCollectionModal.on("hidden.bs.modal", () => {
      $(".backend-requests__new-collection-form").trigger("reset");

      $(".backend-collections__error-field").removeClass(
        "backend-collections__error-field"
      );
      $(".backend-collections__new-collection-form-errors").html("");
    });

    // Attach errors if any on create new request from modal or just submit if no errors
    $(".backend-collections__submit-create-form").click(async (e) => {
      e.preventDefault();

      const errors = await getFormErrors();

      $(".backend-collections__error-field").removeClass(
        "backend-collections__error-field"
      );
      $(".backend-collections__new-collection-form-errors").html("");

      if (!errors) {
        $(".backend-collections__new-collection-form").submit();
      } else {
        Object.keys(errors).forEach((err) => {
          const $field = $(
            `.backend-collections__new-collection-form [name="collection[${err}]"]`
          );
          $field.addClass("backend-collections__error-field");

          if (err === "base") {
            $(".backend-collections__new-collection-form-errors").append(
              `<li>${errors[err]}</li>`
            );
          } else {
            $(".backend-collections__new-collection-form-errors").append(
              `<li>${err}: ${errors[err]}</li>`
            );
          }
        });
      }
    });

    // Validate create form via backend
    async function getFormErrors() {
      const data = $(".backend-collections__new-collection-form").serialize();

      try {
        await $.ajax({
          data,
          url: "collections/validate",
          type: "GET",
        });

        return false;
      } catch (e) {
        return e.responseJSON || {};
      }
    }
  },
});
