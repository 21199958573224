// rails:attachBindings event is fired by rails-ujs just before it attaches its own handlers, so the custom handlers
// below are attached *before* the rails-ujs handlers.
$(document).on("rails:attachBindings", () => {
  function handlePrompt(event) {
    const message = $(event.currentTarget).data("prompt");
    const result = window.prompt(message); // eslint-disable-line no-alert

    if (result === "litcharts") {
      // User entered correct text
      return true;
    }

    if (result == null) {
      // User clicked cancel
    } else {
      // User did not enter the correct text.
      window.alert("Sorry, please enter the correct text."); // eslint-disable-line no-alert
    }

    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  // Attach listeners to document so they're ready ASAP without having to wait for DOM ready,
  // improves reliability during testing.
  $(document).on("submit", "form[data-prompt]", handlePrompt);

  // It is essential that this a[data-prompt] handler is attached on the rails:attachBindings event, so
  // this custom handler will run *before* the Rails UJS a[data-method] handler, allowing a cancelled
  // or failed prompt to stop click event propagating to the a[data-method] handler.
  $(document).on("click", "a[data-prompt]", handlePrompt);
});
