import LcView from "lib/shared/view";
import EssayPromptsChannel from "channels/essay_prompts_channel";

export default LcView.create({
  ready() {
    $("body").on("submit", "form#prototype-form", function () {
      $.ajax({
        type: "POST",
        url: $(this).attr("action"),
        data: $(this).serialize(),
        dataType: "json",
        beforeSend() {
          $("#result-indicator").html("Submitted. Please wait.");
          $("#guide-portion-area").html("");
          $("#response-area").html("");
          $("#prompt-area").html("");
        },
        success(data, _status, _settings) {
          const finalPrompt = data.final_prompt;
          const chunks = data.resource_materials;

          $("#prompt-area").text(finalPrompt);
          $("#guide-portion-area").text(chunks);
        },
        error(data, status, _settings) {
          if (status === "timeout") {
            $("#result-indicator").html("Timed out.");
          } else {
            $("#result-indicator").html(data.responseText);
          }
        },
      });
      return false;
    });
    EssayPromptsChannel();
  },
});
