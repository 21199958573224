import React from "react";
import PropTypes from "prop-types";
import helpers from "helpers/base";
import classnames from "classnames";

class Checkbox extends React.Component {
  static propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    noLabel: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    containerClass: PropTypes.string,
  };

  static defaultProps = {
    checked: false,
    disabled: false,
    label: null,
    noLabel: false,
    value: "selected",
  };

  constructor(props) {
    super(props);

    this.inputId = this.props.id || helpers.generateComponentUuid("checkbox");
  }

  renderLabel() {
    const { children, label, noLabel } = this.props;

    if (noLabel) {
      return null;
    }

    return (
      <label className="lc-checkbox__label" htmlFor={this.inputId}>
        {label || children}
      </label>
    );
  }

  render() {
    return (
      <div className={classnames("lc-checkbox", this.props.containerClass)}>
        <input
          className="lc-checkbox__input"
          defaultChecked={this.props.checked}
          disabled={this.props.disabled}
          id={this.inputId}
          name={this.props.name}
          onChange={this.props.onChange}
          type="checkbox"
          value={this.props.value}
        />
        {this.renderLabel()}
      </div>
    );
  }
}

export default Checkbox;
