import LcView from "lib/shared/view";
import PoetryPoemsShow from "views/poetry/poems/show";

export default LcView.create(
  Object.assign(PoetryPoemsShow, {
    ajaxPoemLoad() {
      const id = $(this.selectors.MAIN).data("id");
      return $.ajax({
        url: `/admin/poems/${id}/load`,
        type: "GET",
        dataType: "json",
      });
    },
  })
);
