// We only want to show focus styles if the user is interacting with the app via keyboard.
// If the user is interacting via mouse, they can already see where their focus is.

// This method allows us to add or remove custom focus styles depending on user input type like:
// => input:focus { outline: 1px solid blue; }
// => body.using--mouse input:focus { outline: none; }

// This allows for a global solution rather than an input by input solution
// See https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press/51093815#51093815 for more details

export default {
  initialize: () => {
    const onKeydown = () => {
      document.body.classList.remove("using--mouse");
    };
    const onMousedown = () => {
      document.body.classList.add("using--mouse");
    };
    document.body.addEventListener("mousedown", onMousedown);
    document.body.addEventListener("keydown", onKeydown);
  },
};
