export default {
  isElementVisible(element) {
    if (
      element.offsetWidth ||
      element.offsetHeight ||
      element.getClientRects().length
    ) {
      return true;
    }
    return false;
  },

  scrollTo(element, offset = {}) {
    window.scrollTo({
      top: element.offsetTop + (offset.y || 0),
      left: offset.x || 0,
      behavior: "smooth",
    });
  },

  getScrollPosition() {
    const element = document.documentElement;
    const top =
      (window.pageYOffset || element.scrollTop) - (element.clientTop || 0);
    const left =
      (window.pageXOffset || element.scrollLeft) - (element.clientLeft || 0);

    return { top, left };
  },
};
