// Use this by adding classes of the style lc-u-{property}-{value}{unit}-{size} to your templates
// Example: The class lc-u-max-width-640px will add the CSS property "max-width: 640px"
// Example: lc-u-max-width-500px-sm will add the CSS property "max-width: 500px" on small screens
// NOTE: This is limited to static templates, since it runs only on page load, so it won't
// behave well with React components

export default {
  initialize: () => {
    const styleSheet = document.createElement("style");
    document.head.appendChild(styleSheet);

    const elements = document.querySelectorAll('[class*="lc-u-"]');
    const breakpoints = {
      xs: "(max-width: 767px)",
      sm: "(min-width: 768px) and (max-width: 991px)",
      md: "(min-width: 992px) and (max-width: 1199px)",
      lg: "(min-width: 1200px)",
    };

    elements.forEach((el) => {
      el.classList.forEach((className) => {
        // Matches lc-u-{property}-{value}{unit}-{size} where size is optional
        const match = className.match(
          /^lc-u-([a-z-]+)-(\d+)([a-z%]*)(?:-([a-z]+))?$/
        );
        if (match) {
          const [, property, value, unit, size] = match;
          const rule = `${property}: ${value}${unit || ""};`;

          if (size && breakpoints[size]) {
            // Responsive style
            const mediaQuery = `@media ${breakpoints[size]} { .${className} { ${rule} } }`;
            if (!styleSheet.textContent.includes(mediaQuery)) {
              styleSheet.textContent += mediaQuery;
            }
          } else {
            // Default style
            const selectorRule = `.${className} { ${rule} }`;
            if (!styleSheet.textContent.includes(selectorRule)) {
              styleSheet.textContent += selectorRule;
            }
          }
        }
      });
    });
  },
};
