import array from "helpers/array";
import dom from "helpers/dom";
import object from "helpers/object";
import string from "helpers/string";
import number from "helpers/number";

const MAX_MOBILE_WIDTH = 992;

export default {
  array,
  dom,
  object,
  string,
  number,

  isMobile() {
    return this.getViewportWidth() < MAX_MOBILE_WIDTH;
  },

  isMobileAgent() {
    return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  },

  getViewportHeight() {
    return this.getViewportDimensions().height;
  },

  getViewportWidth() {
    return this.getViewportDimensions().width;
  },

  getViewportDimensions() {
    return {
      height: Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      ),
      width: Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
      ),
    };
  },

  getCssTransformValue($element, property) {
    let index = 0;
    switch (property) {
      case "scaleX":
        index = 0;
        break;
      case "skewY":
        index = 1;
        break;
      case "skewX":
        index = 2;
        break;
      case "scaleY":
        index = 3;
        break;
      case "translateX":
        index = 4;
        break;
      case "translateY":
        index = 5;
        break;
      default:
        break;
    }
    let str = $element.css("transform").split(",")[index]; // get corresponding translation value
    str = str.replace(/[^\d.-]/g, ""); // remove all non-numeric
    return parseInt(str);
  },

  isLocalhost() {
    return window.location.hostname === "localhost";
  },

  isSignedIn() {
    return !!$("html.signed-in").length;
  },

  isSubscribed() {
    return !!$("html.subscribed").length;
  },

  userIAmA() {
    return $("html").attr("data-i-am-a");
  },

  userType() {
    if (!this.isSignedIn()) {
      return "anonymous";
    }
    return this.isSubscribed() ? "a plus subscriber" : "free tier user";
  },

  showDebug() {
    return $("html").attr("data-environment") === "development";
  },

  hasFunction(obj, fn) {
    return this.isFunction(obj[fn]);
  },

  isFunction(fn) {
    return typeof fn === "function";
  },

  getCurrentTimeInMs() {
    return new Date().getTime().toString();
  },

  generateRandomUuid() {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  },

  generateComponentUuid(componentType) {
    let id = this.generateRandomUuid();
    const $others = $(`#${componentType}-${id}`); // i.e. #lc-checkbox-33jf292jfd34
    while ($others.length) {
      id = this.generateRandomUuid();
    }
    return `${componentType}-${id}`;
  },

  // see https://stackoverflow.com/questions/10730362/get-cookie-by-name
  cookie(key) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${key}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  },

  getQueryParams() {
    const { search } = window.location;
    if (search === "") {
      return {};
    }
    const params = search.slice(1).split("&");
    const qp = {};
    this.array.each(params, (param) => {
      const paramName = param.split("=")[0];
      const paramValue = param.split("=")[1];
      qp[paramName] = paramValue;
    });

    return qp;
  },

  getQueryParam(param) {
    return this.getQueryParams()[param];
  },

  hasQueryParam(param) {
    return param in this.getQueryParams();
  },

  updateQueryParams(keyValuePairs) {
    const newParams = [];
    this.object.each(keyValuePairs, (key, value) => {
      newParams.push(`${key}=${value}`);
    });
    const paramString = newParams.join("&");
    const url =
      window.location.origin +
      window.location.pathname +
      (newParams.length === 0 ? "" : "?") +
      paramString;
    window.history.pushState({}, "", url);
  },

  chosenAddOptions($select, options) {
    const wrappedOptions = this.array.wrap(options);
    this.array.each(wrappedOptions, (option) => {
      let html = "<option value='";
      html += option.value;
      html += "'>";
      html += option.text;
      html += "</option>";
      $select.append(html);
    });
    $select.trigger("chosen:updated");
  },

  chosenSelectOptions($select, options) {
    const wrappedOptions = this.array.wrap(options);
    $select.children("option:selected").prop("selected", null);
    $select.children("option:selected").removeAttr("selected");
    this.array.each(wrappedOptions, (option) => {
      const $option = $select.children(`option[value='${option}']`);
      $option.prop("selected", "selected");
      $option.attr("selected", "selected");
    });
    $select.trigger("chosen:updated");
  },

  // requires all files in a directory from a webpack context function
  requireAll(context) {
    const views = [];
    context.keys().forEach((key) => {
      views.push(context(key));
    });
    return views;
  },

  // Repeat a function n times with a timeout. Have func return true to stop early
  doNTimesWithTimeout(n, func, timeout) {
    if (func()) {
      return;
    }

    if (n <= 1) {
      return;
    }

    setTimeout(() => {
      this.doNTimesWithTimeout(n - 1, func, timeout);
    }, timeout);
  },

  // manually set a global variable to ensure ajax requests are completed before proceeding
  // primarily used by automated tests on script that involves multiple ajax calls in quick succession
  setWaitForAjax() {
    window.waitForAjax = true;
  },

  resetWaitForAjax(timeout = 2000) {
    window.waitForAjax = false;
    setTimeout(() => {
      window.waitForAjax = null;
    }, timeout);
  },

  getContextTypeAndTitle() {
    const context = {};
    if ($("html.books.show").length) {
      context.type = "lit guide";
      context.title = $(".book-component").data("slug");
    } else if ($("html.books.index").length) {
      context.type = "lit guides";
    } else if ($("html.poems.index").length) {
      context.type = "poetry guides";
    } else if ($("html.poems.show").length) {
      context.type = "poetry guide";
      context.title = $("#poems-show").data("slug");
    } else if ($("html.lit-terms").length) {
      context.type = "lit term";
      const titleIndex = 2;
      context.title = window.location.pathname.split("/")[titleIndex];
    } else if ($("html.shakespeare-translations").length) {
      context.type = "shakespeare translation";
      context.title = $(".container.shakespeare").data("translation");
    } else if ($("html.search").length) {
      context.type = "search";
    } else if ($("html.home").length) {
      context.type = "home";
    }
    return context;
  },
};
