import LcView from "lib/shared/view";

export default LcView.create({
  ready: () => {
    //
    // Bind element num toggle
    //
    $("#has-num-elements_yes").on("click", () => {
      $(".edit-backend-collection__num-ele-label").removeClass("disabled");
      $("#collection_num_elements").attr("disabled", false);
    });

    $("#has-num-elements_no").on("click", () => {
      $(".edit-backend-collection__num-ele-label").addClass("disabled");
      $("#collection_num_elements").attr("disabled", true);
      $("#collection_num_elements").val("");
    });

    //
    // Bind create new description (collection/element) modals
    //
    $(".edit-backend-collection__submit-create-description").on(
      "click",
      function (e) {
        e.preventDefault();

        const $form = $(this)
          .parent(".modal-footer")
          .siblings(".modal-body")
          .find("form");
        const path = `/admin/${$form.attr("action")}`;

        $.post(path, $form.serialize())
          .done(() => {
            const selector = `#${$form.data("selector")}`;
            const $select = $(selector);
            $(`${selector} option:selected`).removeAttr("selected");
            const val = $form.find("input[type='text']").val();

            $select.append(
              `<option value='${val}' selected='selected'>${val} - NEW</option>`
            );
            $select.trigger("chosen:updated");

            $(".modal").modal("hide");
            $form.find("input[type='text']").val("");
          })
          .fail((data) => {
            $(".edit-backend-collections__form-errors").html(data.errors);
          });
      }
    );

    //
    // Populate element data
    //

    const elementData = [];

    $(".edit-backend-collection__collections-list li").each(function () {
      const $li = $(this);

      const $input = $li.find("input[type='hidden']");

      this.addEventListener("drag", setDragging);
      this.addEventListener("dragover", setDraggedOver);
      this.addEventListener("drop", onDrop);

      elementData.push({
        id: $input.data("id"),
        title: $input.data("title"),
        author: $input.data("author"),
        type: $input.data("type"),
      });
    });

    //
    // Add to element data when selecting a new element
    //

    $(".edit-backend-collection__add-to-collection").on("click", (e) => {
      e.preventDefault();

      const $opt = $("#collection_elements option:selected");
      elementData.push({
        id: $opt.data("id"),
        title: $opt.data("title"),
        author: $opt.data("author"),
        type: $opt.data("type"),
      });

      renderElementList(elementData);
    });

    //
    // Renders list of elements from elementData
    //

    const renderElementList = (data) => {
      const list = document.querySelector(
        ".edit-backend-collection__collections-list"
      );
      list.innerText = "";

      let i = 0;

      data.forEach((item) => {
        const node = document.createElement("li");
        node.setAttribute("draggable", true);
        node.addEventListener("drag", setDragging);
        node.addEventListener("dragover", setDraggedOver);
        node.addEventListener("drop", onDrop);

        if (item.author) {
          node.innerText = `${item.title} (${item.author})`;
        } else {
          node.innerText = item.title;
        }

        const span = document.createElement("span");
        const deleteLink = document.createElement("a");
        deleteLink.setAttribute("href", "#");
        deleteLink.dataset.index = i;
        deleteLink.className = "edit-backend-collection__delete-link";
        deleteLink.innerText = " Delete from collection";

        span.appendChild(deleteLink);
        node.appendChild(span);

        node.dataset.index = i;
        list.appendChild(node);

        const input = document.createElement("input");
        input.setAttribute("type", "text");
        input.setAttribute("hidden", true);
        input.setAttribute("value", `${item.type}-${item.id}`);
        input.setAttribute("name", "collection[elements][]");

        node.appendChild(input);

        $(deleteLink).on("click", bindDeleteElementLink);

        i += 1;
      });
    };

    //
    // Set up drag and drop functionality
    //

    let dragging;
    let draggedOver;

    const setDragging = (e) => {
      dragging = parseInt(e.target.dataset.index);
    };

    function setDraggedOver(e) {
      e.preventDefault();
      draggedOver = parseInt(e.target.dataset.index);
    }

    function onDrop() {
      const [ele] = elementData.splice(dragging, 1);
      elementData.splice(draggedOver, 0, ele);

      renderElementList(elementData);
    }

    //
    // Delete element from collection functionality
    //

    function bindDeleteElementLink(e) {
      e.preventDefault();

      const index = $(this).data("index");

      const ele = elementData[index];

      $(
        ".edit-backend-collection__delete-element-from-collection-modal .modal-body"
      ).html(
        `Title: ${ele.title}, Type: ${ele.type}, Author (if applicable): ${ele.author}`
      );

      $(".edit-backend-collection__delete-element-from-collection-modal").modal(
        "show"
      );

      $(".edit-backend-collection__delete-element-from-collection-modal").data(
        "index",
        index
      );
    }

    $(".edit-backend-collection__delete-link").on(
      "click",
      bindDeleteElementLink
    );

    $(".edit-backend-collection__submit-delete-element").on("click", (e) => {
      e.preventDefault();

      elementData.splice(
        $(
          ".edit-backend-collection__delete-element-from-collection-modal"
        ).data("index"),
        1
      );

      $(".edit-backend-collection__delete-element-from-collection-modal").data(
        "index",
        null
      );
      $(
        ".edit-backend-collection__delete-element-from-collection-modal .modal-body"
      ).html("");
      $(".edit-backend-collection__delete-element-from-collection-modal").modal(
        "hide"
      );

      renderElementList(elementData);
    });
  },
});
