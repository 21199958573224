const selectors = {
  CONTAINER: "#poems-show__container",
  MAIN: "#poems-show",
  HIDE_UNTIL_LOADED: "#poems-show__hide-until-loaded",
  HEADER: "#poems-show__header",
  MOBILE_HEADER: "#poems-show__mobile-header",
  MOBILE_HEADER_MAIN: "#poems-show__mobile-header--main",
  FOOTER: "section.footer",
  DRAWER: "#poems-show__drawer",
  DRAWER_TITLE: "#poems-show__drawer--title",
  DRAWER_CONTENT: "#poems-show__drawer--content",
  PDF_HEADER: "#poems-show__pdf-header",
  TEXT_SCROLLABLE: "#poems-show__poem-text--scrollable",
  TEXT_FIXED: "#poems-show__poem-text--fixed",
  TEXT_MODAL: "#poems-show__poem-text--modal",
  TEXT_MODAL_CATEGORY: ".poem-text-modal__category",
  TEXT_MODAL_TOGGLE: ".poem-text-modal__toggle",
  TEXT_COLUMN: "#poems-show__poem-text--column",
  TEXT_LINK: ".poems-show__poem-text--link",
  TEXT_TITLE: ".poems-show__poem-text--title",
  GUIDE_COLUMN: "#poems-show__guide--column",
  POEM_CONTENT: ".poems-show__poem-content",
  COMPONENTS: ".poems-show__poem-components",
  COMPONENT: ".poems-show__poem-component",
  COMPONENT_VALUE: ".poems-show__poem-component--value",
  COMPONENT_VALUE_REPLACE: ".poems-show__poem-component--value-replace",
  COMPONENT_TITLE: ".poems-show__poem-component--title",
  HOD_PROMO: ".hod-promo",
  HOD_PROMO_CTA: ".hod-promo__cta",
  BANNER_PROMO: ".banner-promo",
  NAV: "#poems__nav",
  NAV_LINK: ".poems__nav--link",
  MOBILE_NAV: "#poems__mobile-nav",
  MOBILE_NAV_LINK: ".poems__mobile-nav--link",
  MOBILE_NAV_TOGGLE: "#poems__mobile-nav--toggle",
  SECTION: ".poems-show__poem-component-section",
  SECTION_TITLE: ".poems-show__poem-component-section--title",
  SECTION_INSTRUCTIONS: ".poems-show__poem-component--instructions",
  COMPONENT_ITEMS: ".poems-show__poem-component-items",
  COMPONENT_ITEM: ".poems-show__poem-component-item",
  COMPONENT_ITEMS_SELECT: ".poem-component-items__select",
  COMPONENT_ITEMS_SELECT_OPTION: ".poem-component-items__select--option",
  ACTIVE_CONTEXT: ".poems-show__poem-component--active-context",
  ACTIVE_CONTEXT_NOTE: ".poem-component__active-context--note",
  ACTIVE_CONTEXT_BLURRED: ".poem-component__active-context--blurred",
  LINE_MAPS:
    ".poem-inline__lm--content-highlight, .poem-inline__lm--content-one-to-one, .poem-inline__lm--value-one-to-one, .poem-inline__lm--value-structure, .poem-inline__lm--content-rhyme-scheme, .poem-inline__lm--value-rhyme-scheme, .poem-inline__lm--structure-line", // eslint-disable-line max-len
  LINE_MAP_CONTENT_STRUCTURE: ".poem-inline__lm--content-structure",
  LINE_MAP_CONTENT_ONE_TO_ONE: ".poem-inline__lm--content-one-to-one",
  LINE_MAP_VALUE_ONE_TO_ONE: ".poem-inline__lm--value-one-to-one",
  LINE_MAP_VALUE_STRUCTURE: ".poem-inline__lm--value-structure",
  LINE_MAP_VALUE_RHYME_SCHEME: ".poem-inline__lm--value-rhyme-scheme",
  INLINE_LINK: ".poem-inline__link",
  MODAL_LIT_TERM: "#poems-show__lit-term-modal",
  MODAL_LIT_TERM_TITLE: "#poems-show__lit-term-modal--title",
  MODAL_LIT_TERM_DEFINITION: "#poems-show__lit-term-modal--definition",
  MODAL_LIT_TERM_LINK: "#poems-show__lit-term-modal--link",
  PROMO: ".poems-show__promo",
  PROMO_MAIN: ".poems-show__promo--main",
  PROMO_ALT: ".poems-show__promo--alt",
  IS_FOCUSED: ".is-focused",
  CONTEXT_SWITCHER: ".poem-context-switcher",
  AI_TEXT_TOGGLE: ".ai-text-toggle",
  LC_TEXT_TOGGLE: ".lc-text-toggle",
  POEM_GUIDE_CONTENT: ".poem-guide-content",
  POEM_AI_TOOL: ".poem-ai-tool",
  MOBILE_HEADER_POEM_TITLE: ".mobile-header__title--poem-title",
};

export default selectors;
