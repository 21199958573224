import LcView from "lib/shared/view";
import helpers from "helpers/base";

export default LcView.create({
  ready() {
    this.searchQueryKey = "query";
    this.setListeners();
  },

  setListeners() {
    /* eslint-disable no-param-reassign */

    // homepage has multiple search inputs with different placeholder values per viewport size
    // ensure only current visible input contains the query parameter key for the search form
    const setInputQueryKeys = () => {
      const inputs = document.querySelectorAll(this.selectors.SEARCH_INPUT);
      inputs.forEach((input) => {
        const searchContainer = input.closest(
          this.selectors.SEARCH_INPUT_CONTAINER
        );
        if (helpers.dom.isElementVisible(searchContainer)) {
          input.setAttribute("name", this.searchQueryKey);
        } else {
          input.removeAttribute("name");
        }
      });
    };

    // rate-limit the calling of setInputQueryKeys to once every 500 ms
    const limitedSetInputQueryKeys = () => {
      if (this.willCallSetInputQueryKeys) {
        return;
      }
      this.willCallSetInputQueryKeys = setTimeout(() => {
        setInputQueryKeys();
        this.willCallSetInputQueryKeys = null;
      }, 500);
    };

    window.onresize = limitedSetInputQueryKeys;
  },

  selectors: {
    BROWSE_LINK: ".home__browse a",
    A_PLUS_PROMO_LINK: ".home__a-plus-promo a",
    SEARCH_INPUT_CONTAINER: ".home__search .search__input-container",
    SEARCH_INPUT: ".home__search input[type='text']",
    SEARCH_FORM: ".home__search form",
  },
});
