export default {
  // remove an element from an array
  remove(array, element) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] === element) {
        array.splice(i, 1);
        return element;
      }
    }
  },

  // get a new array with a number of elements removed
  without(array, elements) {
    const arrayElements = this.wrap(elements);
    const newArray = [];
    for (let i = 0; i < array.length; i++) {
      if (!arrayElements.includes(array[i])) {
        newArray.push(array[i]);
      }
    }
    return newArray;
  },

  // wraps an object in an array, or returns it if it already is an array
  wrap(elementOrArray) {
    if (Array.isArray(elementOrArray)) {
      return elementOrArray;
    }
    return [elementOrArray];
  },

  // removes all null or undefined elements from an array
  compact(array) {
    return array.filter((element) => element !== undefined && element !== null);
  },

  // call a function on each element in an array
  each(array, callback) {
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      callback(element);
    }
  },

  // call a function on each group of n elements in an array
  eachByN(array, n, callback) {
    for (let i = 0; i < Math.ceil(array.length / n); i++) {
      const set = array.slice(i * n, i * n + n);
      callback(set);
    }
  },

  // shuffle an array, via https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
  shuffle(a) {
    const shuffledArray = [...a];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  },

  // get the last n elements of an array
  last(array, n = 1) {
    if (!array || !array.length) {
      return null;
    }
    if (n === 1) {
      return array[array.length - 1];
    }
    return array.slice(Math.max(array.length - n, 0));
  },

  // collapse an array of arrays into a single array
  flatten(arrays) {
    return [].concat(...arrays);
  },

  // get a new array with all nondistinct elements removed
  unique(array) {
    const newArray = [];
    this.each(array, (element) => {
      if (!newArray.includes(element)) {
        newArray.push(element);
      }
    });
    return newArray;
  },

  // discern whether or not the array is empty
  isEmpty(array) {
    return array === undefined || array.length === 0;
  },

  // get a single element from an array based on a property value
  findBy(array, property, value) {
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (value === undefined) {
        if (element[property]) {
          return element;
        }
      } else if (element[property] === value) {
        return element;
      }
    }
    return null;
  },

  // sort an array based on a property value
  sortBy(array, properties, asc = true) {
    const sortProperties = this.wrap(properties);
    const newArray = array.sort((a, b) => {
      for (let i = 0; i < sortProperties.length; i++) {
        const property = sortProperties[i];
        if (a[property] < b[property]) {
          return -1;
        }
        if (a[property] > b[property]) {
          return 1;
        }
      }
      return 0;
    });
    if (!asc) {
      return newArray.reverse();
    }
    return newArray;
  },

  // discern whether or not an array is a subset of another array
  isSubset(subset, superset) {
    return subset.every((element) => superset.includes(element));
  },

  // returns array1 - array2
  difference(array1, array2) {
    const array = [];
    for (let i = 0; i < array1.length; i++) {
      if (!array2.includes(array1[i])) {
        array.push(array1[i]);
      }
    }
    return array;
  },

  // get a random subset of N elements from an array
  // singularized if N = 1
  sample(array, n = 1) {
    const l = array.length;
    const m = Math.min(n, l); // can only take up to l elements from an l-sized array
    const results = [];
    const taken = [];

    while (results.length < m) {
      const i = Math.floor(Math.random() * l);
      if (!taken.includes(i)) {
        results.push(array[i]);
        taken.push(i);
      }
    }

    return results.length === 1 ? results[0] : results;
  },

  // returns an object grouping objects by a value determined by the callback
  group(array, callback) {
    return array.reduce((acc, element) => {
      const key = callback(element);
      (acc[key] = acc[key] || []).push(element);
      return acc;
    }, {});
  },

  // returns an object grouping objects by the value of the given key
  groupBy(array, key) {
    return array.reduce((acc, element) => {
      (acc[element[key]] = acc[element[key]] || []).push(element);
      return acc;
    }, {});
  },

  // populates an array of size n with the return value of the callback
  populate(n, callback) {
    const array = Array(n);
    for (let i = 0; i < n; i++) {
      array[i] = callback(i);
    }
    return array;
  },
};
