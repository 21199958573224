import consumer from "./consumer";

const quizQuestionsChannel = function () {
  consumer.subscriptions.create("QuizQuestionsChannel", {
    connected() {
      // console.log('connected');
    },

    disconnected() {
      // console.log('disconnected')
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      $("#response-area").html(data.body);
    },
  });
};

export default quizQuestionsChannel;
