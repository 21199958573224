import help from "helpers/base";

export default class PoetryLineMapper {
  constructor(type, id, $originalContent, $targetContent, options = {}) {
    this.type = type;
    this.id = id;
    this.$element = $(`[data-line-mapper='${id}']`);
    this.$originalContent = $originalContent;
    this.$targetContent = $targetContent;
    this.contentApplierClass = this.getContentApplierClass();
    this.contentApplierSelector = `.${this.contentApplierClass}`;
    this.valueApplierClass = this.getValueApplierClass();
    this.valueApplierSelector = `.${this.valueApplierClass}`;
    this.mapClass = this.getMapClass();
    this.mapSelector = `.${this.mapClass}`;
    this.options = Object.assign(this.getDefaultOptions(), options);
    this.isExpanded = false;

    if (options.isExpanded) {
      this.toggle();
    }
  }

  getDefaultOptions() {
    return {
      colors: ["1", "2", "3", "4", "5"],
      max: 999,
    };
  }

  helper() {
    if (typeof Lithelp === "undefined" || Lithelp === null) {
      return help;
    }

    return Lithelp;
  }

  // toggle the line mapper's collapsed or expanded state
  toggle() {
    const $content = this.$element.find(".line-mapping__content");
    const $toggle = this.$element.find("#line-mapping__toggle--main");
    const baseText = $toggle.data("base-text");

    $content.toggle();
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      $toggle.html(
        `Hide ${baseText}<i class='fa fa-chevron-down spacing__margin-left--0p5'></i>`
      );
    } else {
      $toggle.html(
        `Show ${baseText}<i class='fa fa-chevron-up spacing__margin-left--0p5'></i>`
      );
    }
  }

  // apply class 'applierClass' to the current selection
  highlightSelection() {
    const selection = this.getSelection();
    if (!selection.toString()) {
      return;
    }

    const id = this.getNewLmId();
    const selectionId = `selection-${this.getNewLmId()}`;
    const colorId = this.selectedColorId || this.getNextColorId();
    const attributes = { "data-lm-id": id };
    if (this.type === "highlight") {
      attributes["data-color"] = colorId;
    }
    if (this.type === "structure") {
      attributes["data-selection-id"] = selectionId;
    }

    const contentApplier = rangy.createClassApplier(this.contentApplierClass, {
      elementAttributes: attributes,
      useExistingElements: false,
    });

    contentApplier.toggleSelection();
    this.deselect();

    if (this.getAllLineMapIds().length > this.options.max) {
      this.remove(this.getOldestLineMapId());
    }

    if (this.options.afterHighlight) {
      this.options.afterHighlight(id, this);
    }
  }

  // create a line mapping between the current selection and the previous highlight
  mapSelection() {
    const selection = this.getTextEditorSelection();
    if (!selection.toString()) {
      return;
    }

    const id = this.getNewLmId();
    const colorId = this.selectedColorId || this.getNextColorId();
    const attributes = { "data-lm-id": id, "data-color": colorId };

    const valueApplier = rangy.createClassApplier(this.valueApplierClass, {
      elementAttributes: attributes,
    });
    valueApplier.toggleSelection(this.options.textEditor.getNativeWindow());

    this.options.textEditor.deselect();
    this.options.textEditor.didChange();

    const $lineMaps = this.$originalContent.find(this.contentApplierSelector);
    $lineMaps.each((i, element) => {
      $(element).attr(attributes);
      if (i === 0) {
        $(element).attr("data-lm-fot", "");
      }
      if (i === $lineMaps.length - 1) {
        $(element).attr("data-lm-lot", "");
      }
    });
    $lineMaps.addClass(this.mapClass).removeClass(this.contentApplierClass);

    if (this.options.afterMap) {
      this.options.afterMap(id, this);
    }
  }

  // remove line mappings by id
  remove(ids) {
    const that = this;
    this.helper().array.each(this.helper().array.wrap(ids), (id) => {
      const $lineMaps = that.$originalContent.find(`[data-lm-id='${id}']`);
      $lineMaps.each((i, element) => {
        $(element).replaceWith($(element).html());
      });
      that.removeLineMapping({ "data-lm-id": id });
    });
  }

  selectColor(id) {
    this.selectedColorId = id;
  }

  // generate a new line mapping id
  getNewLmId() {
    return this.helper().getCurrentTimeInMs(); // last 8 digits
  }

  // get the next color id based on the options.colors
  getNextColorId() {
    const newestId = this.getNewestLineMapId();
    const $newest = this.$originalContent.find(
      this.getSelectorForLineMapId(newestId)
    );
    const newestColor = $newest.data("color");
    const newestColorIndex = newestColor
      ? this.options.colors.indexOf(newestColor.toString())
      : -1;
    const nextColorIndex = (newestColorIndex + 1) % this.options.colors.length;
    return this.options.colors[nextColorIndex];
  }

  getContentApplierClass() {
    return {
      "one-to-one": "poem-inline__lm--content-one-to-one-new",
      highlight: "poem-inline__lm--content-highlight",
      structure: "poem-inline__lm--content-structure-new",
      "rhyme-scheme": "poem-inline__lm--content-rhyme-scheme-new",
    }[this.type];
  }

  getValueApplierClass() {
    return {
      "one-to-one": "poem-inline__lm--value-one-to-one",
      structure: "poem-inline__lm--value-structure",
      "rhyme-scheme": "poem-inline__lm--value-rhyme-scheme",
    }[this.type];
  }

  getMapClass() {
    return {
      "one-to-one": "poem-inline__lm--content-one-to-one",
      highlight: "poem-inline__lm--content-highlight",
      structure: "poem-inline__lm--content-structure",
      "rhyme-scheme": "poem-inline__lm--content-rhyme-scheme",
    }[this.type];
  }

  // get current selection
  getSelection() {
    if (window.getSelection) {
      return window.getSelection();
    }
    if (document.selection) {
      return document.selection;
    }
    return null;
  }

  getTextEditorSelection() {
    const w = this.options.textEditor.getNativeWindow();
    return w.getSelection ? w.getSelection() : null;
  }

  getOldestLineMapId() {
    return this.getAllLineMapIds().sort()[0];
  }

  getNewestLineMapId() {
    return this.helper().array.last(this.getAllLineMapIds().sort());
  }

  // remove current selection
  deselect() {
    const selection = this.getSelection();
    if (selection)
      selection.empty ? selection.empty() : selection.removeAllRanges();
  }

  // apply line mapping style to the current selection in the text editor
  applyLineMapping(attributes) {
    if (!this.options.textEditor) {
      throw new Error(
        "Can't apply line mapping: no textEditor option provided to PoetryLineMapper"
      );
    }

    const style = {
      element: "span",
      attributes: Object.assign(attributes, {
        class: this.getValueApplierClass(),
      }),
    };
    this.options.textEditor.createAndApplyStyle(style);
  }

  // remove line mapping from elements matching the provided attributes
  removeLineMapping(attributes) {
    if (!this.options.textEditor) {
      throw new Error(
        "Can't remove line mapping: no textEditor option provided to PoetryLineMapper"
      );
    }

    const selector = this.helper()
      .object.map(attributes, (name, value) => `[${name}='${value}']`)
      .join("");
    const $lineMaps = this.$targetContent.find(selector);
    $lineMaps.each((i, element) => {
      $(element).replaceWith($(element).html());
    });
    this.options.textEditor.didChange();
  }

  createStructureMappingLine(id, color = null) {
    const $lineMaps = this.$originalContent.find(`[data-lm-id='${id}']`);
    // ignore line numbers
    let lineMaps = $lineMaps
      .filter(
        (i) => !$($lineMaps[i]).parent().hasClass("poem-inline__line-number")
      )
      .toArray();

    lineMaps = $.uniqueSort(lineMaps);
    let selectionIds = lineMaps.map((lineMap) =>
      $(lineMap).data("selection-id")
    );
    selectionIds = this.helper().array.unique(selectionIds);
    const lineMapGroups = {};

    this.helper().array.each(selectionIds, (selectionId) => {
      if (selectionId) {
        lineMapGroups[selectionId] = $(lineMaps).filter(
          `[data-selection-id='${selectionId}']`
        );
      } else {
        lineMapGroups[selectionId] = $(lineMaps).filter(
          ":not([data-selection-id])"
        );
      }
    });

    this.helper().object.each(lineMapGroups, (selectionId, lineMapGroup) => {
      const colorId = color || $(lineMapGroup[0]).attr("data-color");
      const attrs = {
        "data-lm-id": id,
        "data-color": colorId,
      };

      const top =
        $(lineMapGroup[0]).position().top + this.$originalContent[0].scrollTop;
      const left = this.hasStructureMappingParent(id, selectionId) ? 12 : 0;
      const last = $(lineMapGroup[lineMapGroup.length - 1]);
      const height =
        last.position().top + last.height() - $(lineMapGroup[0]).position().top;
      const width = 8;

      const $structureLine = $(
        "<div class='poem-inline__lm--structure-line'></div>"
      );
      this.helper().object.each(attrs, (attr, val) => {
        $structureLine.attr(attr, val);
      });
      $structureLine.css("position", "absolute");
      $structureLine.css("left", left);
      $structureLine.css("top", top);
      $structureLine.css("height", `${height}px`);
      $structureLine.css("width", `${width}px`);

      this.$originalContent.append($structureLine);
    });
  }

  hasStructureMappingParent(lmId, selectionId = null) {
    let correctedSelectionId = selectionId;
    if (correctedSelectionId === "undefined") {
      correctedSelectionId = null;
    }
    const that = this;
    let ids = this.getAllLineMapIds();
    const selectionIds = this.getAllSelectionIds();
    // remove lm ids that have a selection id
    ids = ids.filter((id) => {
      const $lineMap = $(that.getSelectorForLineMapId(id));
      return !$lineMap.attr("data-selection-id");
    });

    const $lineMapsById = {};
    const $selectionsById = {};

    // object mapping lmids to all line mappings with given lmid
    this.helper().array.each(ids, (id) => {
      $lineMapsById[id] = that.$originalContent.find(
        that.mapSelector + that.getSelectorForLineMapId(id)
      );
    });
    // object mapping selection ids to all line mappings with given selection id
    this.helper().array.each(selectionIds, (id) => {
      $selectionsById[id] = that.$originalContent.find(
        that.mapSelector + that.getSelectorForSelectionId(id)
      );
    });

    const paragraphsById = {};
    // object mapping lm and selection ids to parent paragraph tags
    this.helper().object.each($lineMapsById, (id, $lineMaps) => {
      paragraphsById[id] = $lineMaps.closest("p").toArray();
    });

    this.helper().object.each($selectionsById, (id, $selections) => {
      paragraphsById[id] = $selections.closest("p").toArray();
    });

    const paragraphs = correctedSelectionId
      ? paragraphsById[correctedSelectionId]
      : paragraphsById[lmId];

    // does a block of linemaps completely encompass this line map?
    const hasLmParent = this.helper()
      .array.without(ids, lmId)
      .some((id) =>
        that.helper().array.isSubset(paragraphs, paragraphsById[id])
      );

    // does a selection completely encompass this selection?
    const hasSelectionParent = this.helper()
      .array.without(selectionIds, correctedSelectionId)
      .some((id) =>
        that.helper().array.isSubset(paragraphs, paragraphsById[id])
      );

    return hasLmParent || hasSelectionParent;
  }

  getSelectorForLineMapId(id) {
    return `[data-lm-id='${id}']`;
  }

  getSelectorForSelectionId(id) {
    return `[data-selection-id='${id}']`;
  }

  getAllLineMapIds() {
    const selector =
      this.type === "highlight"
        ? this.contentApplierSelector
        : this.mapSelector;
    return this.helper().array.unique(
      this.$originalContent
        .find(`${selector}[data-lm-id]`)
        .map((i, element) => $(element).data("lm-id"))
    );
  }

  getAllSelectionIds() {
    const selector =
      this.type === "highlight"
        ? this.contentApplierSelector
        : this.mapSelector;
    return this.helper().array.unique(
      this.$originalContent
        .find(`${selector}[data-selection-id]`)
        .map((i, element) => $(element).data("selection-id"))
    );
  }

  static selectLineMapper(type) {
    const selector = `[data-line-mapper][data-line-mapper-type='${type}']`;
    const $lineMapper = $(selector);
    if (!$lineMapper.length) {
      return null;
    }
    return $lineMapper;
  }
}
