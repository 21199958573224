export default {
  // call a function on each key, value pair in the object
  each(obj, fn) {
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
      const value = obj[keys[i]];
      fn(keys[i], value);
    }
  },

  // create an array from a function call on each key, value pair in the object
  map(obj, fn) {
    const array = [];
    this.each(obj, (key, value) => {
      array.push(fn(key, value));
    });
    return array;
  },

  // access a nested property on an object safely
  dig(obj, key) {
    if (!obj || !key) {
      return null;
    }
    const props = key.split(".");
    if (!(typeof obj === "object")) {
      return null;
    }
    if (props.length === 1) {
      return obj[props[0]];
    }
    return this.dig(obj[props[0]], props.slice(1).join("."));
  },

  // obj1 - obj2
  difference(obj1, obj2) {
    const delta = {};
    this.each(obj1, (key, value) => {
      if (obj2[key] !== value) {
        delta[key] = value;
      }
    });
    return delta;
  },

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  },
};
