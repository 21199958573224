// Perform a series of async functions in the order they are pushed to the queue
// like a series of ajax requests
//
// Usage:
//   const myFunc = async function(params) { await $.ajax(...) }
//   const queue = new AsyncQueue();
//   queue.push(myFunc, { data: 1 });
//   queue.push(myFunc, { data: 2 });
//

class AsyncQueue {
  constructor() {
    this.queue = [];
    this.working = false;
    this.currentFunc = null;
  }

  // Push an async func with its params to be called by the queue. Can be called
  // while the queue is already working
  push(asyncFunc, params) {
    this.queue.push({ asyncFunc, params });

    this.work();
  }

  // Use when you want at most one element in the queue, like for a search filter
  // as the user is typing
  forcePush(asyncFunc, params) {
    this.queue[0] = { asyncFunc, params };

    this.work();
  }

  // Call to start processing the queue. Can be called multiple times without
  // adverse effects while the queue is working
  async work() {
    if (this.working || this.queue.length === 0) return;

    this.working = true;

    while (this.working) {
      if (this.queue.length === 0) {
        this.working = false;
      } else {
        const { asyncFunc, params } = this.queue.shift();

        this.currentFunc = asyncFunc;

        // eslint-disable-next-line no-await-in-loop
        await asyncFunc(params);

        this.currentFunc = null;
      }
    }
  }
}

export default AsyncQueue;
